import isBisNumber from './bisNumber/isBisNumber';
import formatSsin from './formatting/format';
import transformUnFormatted from './formatting/transformUnFormatted';
import unFormatSsin from './formatting/unFormat';
import getSsinBirthDate from './parsing/getSsinBirthDate';
import getSsinGender from './parsing/getSsinGender';
import ssinValidation, { ssinCheckModulo97 } from './validation/validation';

export default class Ssin {
  constructor(ssin = '') {
    const val = unFormatSsin(ssin);

    this.getUnformatted = () => transformUnFormatted(val);
    this.getFormatted = () => formatSsin(val);

    this.getBirthDate = () => getSsinBirthDate(ssin);
    this.getGender = () => getSsinGender(ssin);
    this.isValid = () => ssinCheckModulo97(ssin);

    this.isBisNumber = () => isBisNumber(ssin);

    // Return formatted ssin as default JSON export
    this.toJSON = () => this.getFormatted();
  }

  static fromObject({ ssin }) {
    return new Ssin(ssin);
  }
}

export { formatSsin, ssinValidation, unFormatSsin };
