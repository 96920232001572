// to delete when stopping Edge 13 support

(function() {
  if (typeof Object.values === "function") return false;

  function values(array) {
    return Object.keys(array).map(function(key) {
      return array[key];
    });
  }

  Object.values = values;
})();

(function() {
  if (typeof Object.assign === "function") return false;

  Object.assign = function(target, varArgs) {
    // .length of function is 2
    "use strict";
    if (target == null) {
      // TypeError if undefined or null
      throw new TypeError("Cannot convert undefined or null to object");
    }

    var to = Object(target);

    for (var index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index];

      if (nextSource != null) {
        // Skip over if undefined or null
        for (var nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
    return to;
  };
})();
