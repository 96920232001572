import Calendar from "shared/models/calendar.js";
import ReactTurbolinks from "patient/react_turbolinks.js";
import Redirecter from "patient/components/appointments/show/redirecter";

export default function() {
  if ($(".redirecter").length > 0) {
    const calendar = Calendar.parseCalendarHash(
      JSON.parse($("#preloaded-calendar").text())
    );
    ReactTurbolinks.mountComponent(
      Redirecter,
      { calendar },
      $(".redirecter").get(0)
    );
  }
}
