import React, { useState }  from "react";
import AppointmentStatusLogo from "./appointment_status_logo";


const AppointmentStatusToggler = ( {status, updateStatus, label}) => {
    const [toggle, setToggle] = useState(false);
    const upgradeStatus = () => {
        updateStatus(toggle ? null : status);
        setToggle(!toggle);
    }

    return (
        <>
            <div className="button-toggle toggle-container" onClick={() => upgradeStatus()}>
                <div className={`toggle-switch ${toggle ? status + '-bg-color on' : 'off'}`}>
                    <div className="icon-circle">
                        <AppointmentStatusLogo
                            status={status}
                            showBgColor={false}/>
                    </div>
                </div>
                <div className="toggle-label">
                    {label}
                </div>
            </div>

        </>
    )
}
export default AppointmentStatusToggler;