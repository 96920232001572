export default class ServiceSelect extends React.Component {
  onChange = event => {
    var serviceCode = event.target.value;
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <select
        readOnly={this.props.readOnly}
        value={this.props.selectedServiceCode || ""}
        onChange={this.onChange}
        className="form-control input-sm clean select-service"
        name={this.props.name || "select-service"}
        id={this.props.id}
        tabIndex={this.props.tabIndex}
      >
        <option value="">{I18n.get().t("select_service")}</option>
        {this.props.services.map(service => (
          <option value={service.label} key={service.id}>
            {service.translation}
          </option>
        ))}
      </select>
    );
  }
}
