import ReactDOM from "react-dom";
import Calendar from "shared/models/calendar.js";
import Service from "shared/models/service.js";
import NewAppointmentForm from "../components/appointments/new/form.js";

export default function() {
  const newAppointmentWrapper = document.getElementById("new_appointment");
  if (newAppointmentWrapper) {
    const calendar = Calendar.parseCalendarHash(
      JSON.parse(document.getElementById("preloaded-calendar").text)
    );

    const preloadedService = document.getElementById("preloaded-service");
    let service;
    if (preloadedService) {
      service = Service.parseServiceHash(
        JSON.parse(document.getElementById("preloaded-service").text)
      );
    }

    /**
     * Render the new React form for creating an appointment
     */
    ReactDOM.render(
      <NewAppointmentForm calendar={calendar} service={service} />,
      newAppointmentWrapper
    );
  }
}
