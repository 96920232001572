import React from "react";
import GoogleMapsLoader from "patient/google_maps_loader.js";

export default class MapPlaceholder extends React.Component {
    constructor(props) {
        super(props);
        let displayedCalendars = props.calendars ? props.calendars.filter(cal => !cal.hideAddress && cal.lat && cal.lon) : [];
        this.state = {
            displayedCalendars: displayedCalendars
        };
    }

    select = () => {
        this.setState(this.initialiseMap);
    }

    initialiseMap = () => {
        const {displayedCalendars} = this.state;
        GoogleMapsLoader.load((loadMap) => {
        let map = $(this._map);
        map.show();
        let options = {
            rootMargin: "50px",
            threshold: 0
        };
        let mapObj = map.get(0);

        let observer = new IntersectionObserver(function(entries, observer) {
            // Detect intersection https://calendar.perfplanet.com/2017/progressive-image-loading-using-intersection-observer-and-sqip/#comment-102838
            let isIntersecting =
                typeof entries[0].isIntersecting === "boolean"
                    ? entries[0].isIntersecting
                    : entries[0].intersectionRatio > 0;
            if (isIntersecting) {
                loadMap(mapObj, displayedCalendars);
                observer.unobserve(mapObj);
            }
        }, options);

        observer.observe(mapObj);
        });
    }

    render() {
        const {displayedCalendars} = this.state;
        return(
            <div className={displayedCalendars.length > 0 ? undefined : "hidden"}>
                <div className="googlemap-large" ref={m => (this._map = m)}>
                    <div className="map-placeholder">
                        <div>
                            <button className="btn btn-primary btn-sm" onClick={this.select}>
                                {I18n.get().translate("click_here_to_show_map")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
