import ProgendaUtils from "shared/progenda_utils.js";
import PI from "services/progenda_interface.js";
import camelize from "camelize";

const Appointment = {};

Appointment.editAppointment = function(
  calendar,
  appointmentId,
  start,
  end,
  allDay,
  success
) {
  return PI.patch({
    url: PI.adminCalendarAppointmentPath(calendar.slug, {
      id: appointmentId
    }),
    data: {
      appointment: {
        start: moment(start.toISOString()).unix(),
        stop: moment(end.toISOString()).unix(),
        allDay: allDay
      }
    }
  }).then(() => {
    success();
  });
};

Appointment.findAppointmentsBetween = function(object, start, stop, params) {
  const ranges = [
    {
      start: moment(start).format(),
      stop: moment(stop).format()
    }
  ];
  return new Promise(resolve => {
    PI.get({
      url: PI.adminScheduleAppointmentsPath(object.schedule.id),
      data: $.extend({ ranges }, params)
    }).then(({ data }) => {
      resolve(data);
    });
  });
};

Appointment.findAppointmentsOnMultipleCalendarsBetween = function(
  objects,
  start,
  stop,
  params
) {
  const ranges = [
    {
      start: moment(start).format(),
      stop: moment(stop).format()
    }
  ];
  return new Promise(resolve => {
    PI.get({
      url: PI.adminScheduleAppointmentsPath(
        ProgendaUtils.retrieveScheduleIds(objects).toString()
      ),
      data: $.extend({ ranges }, params)
    }).then(({ data }) => {
      resolve(data);
    });
  });
};

Appointment.findRecurrenceGroup = function(object, recurrenceGroupId, params) {
  return new Promise(resolve => {
    PI.get({
      url: PI.adminScheduleAppointmentsPath(object.schedule.id),
      data: $.extend({ recurrence_group_id: recurrenceGroupId, include_all_day_appointments: true}, params)
    }).then(({ data }) => {
      data.appointments.sort((a, b) => {
        return a.appointment.start - b.appointment.start;
      });
      resolve(data);
    });
  });
};

Appointment.parseAppointmentHash = function(hash) {
  return {
    ...hash
  };
};

export default Appointment;
