let context = null;

export default {
  setContext: function(newContext) {
    context = newContext;
  },
  getContext: function() {
    return context;
  }
};
