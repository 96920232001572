import Modal from "shared/components/modal.tsx";

class StepModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { step: null };
  }

  show = callback => {
    if (this.state.step === null) {
      this._modal.show();
      this.nextStep(0);
    }
    if (callback) {
      callback();
    }
  };

  hide = callback => {
    this.setState({ step: null }, () => {
      this._modal.hide();
      if (callback) {
        callback();
      }
    });
  };

  stepCompleted = () => {
    this.nextStep(this.state.step + 1);
  };

  reset = () => {
    this.nextStep(0);
  };

  visible = () => {
    return this.state.step !== null;
  };

  nextStep(step) {
    const length = this.props.children.length || (this.props.children ? 1 : 0);
    if (this.props.children === undefined || step >= length || step > 100) {
      this.hide();
    } else if (length === 1 || this.props.children[step]) {
      this.setState({ step });
    } else {
      this.nextStep(step + 1);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.step &&
      this.props.children.length &&
      !this.props.children[this.state.step]
    ) {
      this.nextStep(this.state.step);
    }
  }

  render() {
    const children = React.Children.map(
      this.props.children,
      (child, index) =>
        child && (
          <div className={this.state.step == index ? "" : "collapse"}>
            {React.cloneElement(child, {
              onHide: this.stepCompleted
            })}
          </div>
        )
    );
    return (
      <Modal
        ref={c => (this._modal = c)}
        large={this.props.large ? true : false}
        preventClick={this.props.preventClick}
      >
        {children}
      </Modal>
    );
  }
}

export default StepModal;
