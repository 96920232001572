const Colors = {
  helenaProColors: [
    "#208680",
    "#2EC0B7",
    "#6CD2CC",
    "#154256",
    "#AED6E8",
    "#E74C3C",
    "#EE8176",
    "#27B67A",
    "#67CBA2",
    "#2C92BD",
    "#6BB2D1",
    "#4BC8FE",
    "#81D9FE"
  ],
  newColors: [
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#0097A7",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5"
  ],
  oldColors: [
    "#3a87ad",
    "#356635",
    "#730E15",
    "#E99002",
    "#272727",
    "#967eb3",
    "#ff3a9d",
    "#31ff00",
    "#ffd100",
    "#0c008c",
    "#c60030"
  ]
}

const ColorDictionary = {
  progenda: {
    defaultEvent: "rgb(33, 150, 243)",
    canceledEvent: "#58B0F6",
    eventBackground: "#D95C5C",
    eventWhileDragging: "rgba(235,248,250,0.65)",
    unavailableBackground: "#777777",
    red: "#D95C5C"
  },
  helenaPro: {
    defaultEvent: "#2EC0B7",
    canceledEvent: "#6CD2CC",
    eventBackground: "#2EC0B7",
    eventWhileDragging: "rgb(46, 192, 183, 0.65)",
    unavailableBackground: "#BEC7CB",
    defaultPopupLabel: "#1C323B"
  }
};

const getColors = (key) => { return Colors[key] }

const getColor = (key, helenaProUser = false) => {
  if (helenaProUser) {
    return ColorDictionary["helenaPro"][key];
  } else {
    return ColorDictionary["progenda"][key];
  }
};

export { getColor, getColors };
