import { bool, shape, number, string } from "prop-types";
import Mailcheck from "mailcheck";
import LibPhoneNumber from "google-libphonenumber";
import moment from "moment";
import CrossPlatformDatePicker from "shared/components/cross_platform_date_picker.tsx";
import ProgendaUtils from "shared/progenda_utils.js";
import XSSFilter from "shared/components/xss_filter.tsx";
import Tooltip from "shared/components/tooltip.tsx";
import Assets from "patient/assets.js";

import Secrets from "shared/secrets.js";
import PhoneCountries from "shared/phone_countries";
import Ssin from "shared/ssin/index.js"

export default class NewAppointmentForm extends React.Component {
  static propTypes = {
    calendar: shape({
      addressOnPatientForm: bool,
      allowPatientNotes: bool,
      patientNotesAreMandatory: bool,
      birthdateOnPatientForm: bool,
      id: number,
      slug: string,
      contactDesignation: string,
      countryCode: string,
      officialNameInASentence: string,
      askPatientConsent: bool,
      confirmationText: string,
      isMedical: bool,
      enableCaptcha: bool,
      ssinMatching: bool,
    }).isRequired,
    service: shape({
      id: number,
    })
  };

  state = {
    firstName: "",
    lastName: "",
    phone: "",
    phone_with_prefix: "",
    prefix: PhoneCountries.find(({code}) => code === this.props.calendar.countryCode.toUpperCase()).dial_code,
    email: "",
    birthDate: "",
    address: "",
    notes: "",
    gdprAgreed: false,
    captchaToken: "",
    ssin: "",
    validationErrors: {
      phone: null,
      email: null,
      ssin: null
    },
    isLoading: false
  };

  constructor(props) {
    super(props);

    // create all the needed refs
    this._formRef = React.createRef();
    this._captchaRef = React.createRef();
  }

  /**
   * This method enables/disables the Save button
   */
  get submitButtonIsEnabled() {
    // check that all mandatory fields have values

    const {
      firstName,
      lastName,
      phone,
      email,
      birthDate,
      address,
      notes,
      gdprAgreed,
      validationErrors,
      ssin
    } = this.state;

    const {
      calendar: {
        birthdateOnPatientForm,
        askPatientConsent,
        addressOnPatientForm,
        allowPatientNotes,
        patientNotesAreMandatory,
        ssinMatching
      }
    } = this.props;

    if (!lastName.length) {
      return false;
    }

    if (!firstName.length) {
      return false;
    }

    if (!phone.toString().length) {
      return false;
    }

    if (!email) {
      return false;
    } else {
      // validate the email format
      if (!ProgendaUtils.validateEmailFormat(email)) {
        return false;
      }
    }

    if (birthdateOnPatientForm) {
      if (!birthDate.length) {
        return false;
      }
      // if we have value in input we need to verify if it's a correct date
      const momentBirthDate = moment(moment(birthDate, "dd/mm/yyyy"));
      if (!momentBirthDate.isValid()) {
        return false;
      }
    }

    if (allowPatientNotes && patientNotesAreMandatory) {
      if (!notes.length) {
        return false;
      }
    }

    if (addressOnPatientForm) {
      if (!address.length) {
        return false;
      }
    }

    if (askPatientConsent && !gdprAgreed) {
      return false;
    }

    if (ssinMatching) {
      if (!ssin.length) {
        return false;
      }
      if (!new Ssin(ssin).isValid()) {
        return false;
      }
    }

    return true;
  }

  componentDidMount() {
    this.initializeReCaptcha();
    // extract the service id and the start date for the appointment from url
    this.urlQueryObject = ProgendaUtils.URLQueryToObject();
    // read the rails csrf token, it's used in a hidden input below in the form
    this.csrfToken = ProgendaUtils.getCSRFToken();
    $(this._select).selectize({
      options: PhoneCountries.map(country=>({...country, label: country.code + " (" + country.dial_code + ")" })).sort(((a, b) => (a.code > b.code) ? 1 : -1)),
      searchField: ['code', 'dial_code'],
      labelField: 'label',
      valueField: 'dial_code',
      maxItems: 1,
      allowEmptyOption: false,
      onChange: e => {
        this.setPhonePrefix(e);
        this.validatePhoneNumber();
      },
      onDropdownOpen: function () {
        this.previousValue = this.getValue();
        this.clear();
      },
      onBlur: function() {
      if(this.getValue() === '') {
        this.setValue(this.previousValue);
      }}})
  }

  initializeReCaptcha = () => {
    if (!this.props.calendar.enableCaptcha) {
      return;
    }

    window.captchaCallback = () => {
      let onCaptchaSubmit = token => {
        this.setState({isLoading: true});
        this.setState({ captchaToken: token });
        this._formRef.current.submit();
      };
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.render(this._captchaRef.current, {
          sitekey: Secrets.get().recaptchaSoftPublicKey,
          callback: onCaptchaSubmit,
          size: "invisible"
        });
      }
    };
    if (typeof grecaptcha === "undefined") {
      let script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?onload=captchaCallback&render=explicit";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  };

  onFormSubmit = e => {
    if (!this.submitButtonIsEnabled) {
      e.preventDefault();
      return;
    }
    if (
      this.props.calendar.enableCaptcha &&
      typeof grecaptcha !== "undefined"
    ) {
      if (!this.state.captchaToken.length) {
        e.preventDefault();
        grecaptcha.execute();
      }
    }
    else {
      this.setState({isLoading: true});
    }
  };

  setLastName = e => {
    this.setState({ lastName: e.target.value });
  };

  setFirstName = e => {
    this.setState({ firstName: e.target.value });
  };

  setPhoneNumber = e => {
    const phone = e.target.value;
    const formatted_phone = phone.replace(/^0+/, "")

    this.setState({
      phone_with_prefix: this.state.prefix + formatted_phone,
      phone: phone,
      validationErrors: {
        ...this.state.validationErrors,
        phone: null
      }
    });
  };

  setPhonePrefix = prefix => {
    const formatted_phone = this.state.phone.replace(/^0+/, "")

    this.setState({
      prefix: prefix,
      phone_with_prefix: prefix + formatted_phone,
      validationErrors: {
        ...this.state.validationErrors,
        phone: null
      }
    });
  };

  validatePhoneNumber = e => {
    const phone = this.state.phone_with_prefix;

    // verify if phone number is valid
    if (phone !== "" && this.state.phone !== "") {
      try {
        if (!this._phoneUtils) {
          this._phoneUtils = LibPhoneNumber.PhoneNumberUtil.getInstance();
        }
        let number = this._phoneUtils.parseAndKeepRawInput(
          phone,
          this.props.calendar.countryCode.toLowerCase()
        );

        if (!this._phoneUtils.isValidNumber(number)) {
          this.setState({
            validationErrors: {
              ...this.state.validationErrors,
              phone: I18n.get().t("invalid_mobile_number")
            }
          });
        } else if (
          this._phoneUtils.getNumberType(number) ===
          LibPhoneNumber.PhoneNumberType.FIXED_LINE
        ) {
          this.setState({
            validationErrors: {
              ...this.state.validationErrors,
              phone: I18n.get().t("not_mobile_number")
            }
          });
        }
      } catch (e) {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            phone: I18n.get().t("invalid_mobile_number")
          }
        });
      }
    }
  };

  setEmail = e => {
    const email = e.target.value.replace(/ /g, "");
    this.setState({
      email,
      validationErrors: {
        ...this.state.validationErrors,
        email: null
      }
    });
  };

  validateEmail = e => {
    const email = e.target.value;
    // first test the format
    if (!ProgendaUtils.validateEmailFormat(email)) {
      this.setState({
        validationErrors: {
          ...this.state.validationErrors,
          email: I18n.get().t("invalid_email_format")
        }
      });
      return;
    }
    Mailcheck.run({
      email,
      suggested: suggested => {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            email: I18n.get().t("invalid_email", { address: suggested.full })
          }
        });
      },
      empty: element => {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            email: null
          }
        });
      }
    });
  };


  setSsin = e => {
    var ssin = e.target.value;
    this.setState({
      ssin,
      validationErrors: {
        ...this.state.validationErrors,
        ssin: null
      }
    });
  };

  validateSsin = e => {
    const ssin = e.target.value;
    let ssinObject = new Ssin(ssin);
    if (! ssinObject.isValid()) {
      this.setState({
        validationErrors: {
          ...this.state.validationErrors,
          ssin: I18n.get().t("invalid_ssin_value")
        }
      });
    }
  };

  setBirthDate = birthDate => {
    this.setState({ birthDate });
  };

  setAddress = e => {
    this.setState({ address: e.target.value });
  };

  setNotes = e => {
    this.setState({ notes: e.target.value });
  };

  setGDPR = () => {
    this.setState({ gdprAgreed: !this.state.gdprAgreed });
  };

  getSsinTooltipInfo = () => {
    let language = ['nl', 'de'].includes(I18n.get().locale) ? I18n.get().locale : 'fr'
    let imagePathNew = "eID-verso-" + language + "-new.png";
    let imagePathOld = "eID-verso-" + language + "-old.png";

    let eidInfo = "<div class='row'><div class='col-sm-6'>" + I18n.get().translate("new_eid_tooltip_info") +
        "</div><div class='col-sm-6'>" + I18n.get().translate("old_eid_tooltip_info") + "</div></div>";

    let eidPictures = "<div class='row'><div class='col-sm-6'><img src=\"" + Assets[imagePathNew] +
        "\"/></div><div class='col-sm-6'><img src=\"" + Assets[imagePathOld] + "\"/></div></div>"

    let ssiInfo = "<div class='col-sm-6'>" + I18n.get().translate("ssi_tooltip_info") +
        "<img src=\"" + Assets["isi-front.png"] + "\"/></div>" ;

    return  "<div class='flex-row'>" + eidInfo + eidPictures + "</div><div class='row'>" + ssiInfo + "</div>";
  }

  render() {
    const {
      calendar: {
        addressOnPatientForm,
        allowPatientNotes,
        birthdateOnPatientForm,
        patientNotesAreMandatory,
        id,
        slug,
        contactDesignation,
        officialNameInASentence,
        askPatientConsent,
        confirmationText,
        isMedical,
        ssinMatching
      }
    } = this.props;

    let ssinTooltipTitle;
    if (ssinMatching) {
      ssinTooltipTitle = this.getSsinTooltipInfo();
    }

    const {
      firstName,
      lastName,
      phone,
      phone_with_prefix,
      email,
      birthDate,
      address,
      notes,
      ssin,
      validationErrors,
      gdprAgreed,
      captchaToken
    } = this.state;

    return (
      <form
        className="form-horizontal"
        method="POST"
        action={`/calendars/${slug}/appointments`}
        acceptCharset="UTF-8"
        ref={this._formRef}
        onSubmit={this.onFormSubmit}
      >
        <input name="utf8" type="hidden" value="✓" />
        {confirmationText && confirmationText.length && (
          <div className="form-group">
            <div className="col-sm-4"></div>
            <div className="col-sm-6">
              <div className="alert alert-danger">
                <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
                <XSSFilter useSpan allowLinks={true} content={confirmationText} />
              </div>
            </div>
          </div>
        )}
        {this.urlQueryObject && this.urlQueryObject.start && (
          <input
            type="hidden"
            name="appointment[start]"
            value={this.urlQueryObject.start}
          />
        )}
        {this.csrfToken && this.csrfToken.length && (
          <input
            type="hidden"
            name="authenticity_token"
            value={this.csrfToken}
          />
        )}
        {this.urlQueryObject && this.urlQueryObject.service_id && (
          <input
            type="hidden"
            name="appointment[service_id]"
            value={this.urlQueryObject.service_id}
          />
        )}
        {this.urlQueryObject && this.urlQueryObject.now && (
          <input
            type="hidden"
            name="appointment[now]"
            value={this.urlQueryObject.now}
          />
        )}
        {this.urlQueryObject && this.urlQueryObject.utm_source && (
          <input
            type="hidden"
            name="appointment[utm_source]"
            value={this.urlQueryObject.utm_source}
          />
        )}
        {this.urlQueryObject && this.urlQueryObject.utm_medium && (
          <input
            type="hidden"
            name="appointment[utm_medium]"
            value={this.urlQueryObject.utm_medium}
          />
        )}

        <input type="hidden" name="captcha_validation" value={captchaToken} />
        <div ref={this._captchaRef} />

        {ssinMatching && (
            <div className="form-group">
              <label className="control-label col-sm-4">{I18n.get().t("ssin")} *</label>
              <div className="col-sm-6">
                <input
                    className="form-control input-sm"
                    type="text"
                    onChange={this.setSsin}
                    onBlur={this.validateSsin}
                    value={ssin}
                    name="appointment[patient_attributes][ssin_from_patient]"
                    id="appointment_patient_attributes_ssin_from_patient"
                />
                {validationErrors.ssin && (
                    <p id="invalid-ssin" className="help-block">
                      <span className="fa fa-exclamation-circle" aria-hidden="true" />
                      <span className="invalid-reason">{validationErrors.ssin}</span>
                    </p>
                )}
              </div>
              <div className="col-sm-4"></div>
              <div className="col-sm-6">
                <span className="description">
                  {I18n.get().t("ssin_matching_explanation")}&nbsp;
                  <Tooltip
                      tag="span"
                      allowDangerousHtml="ilikedanger"
                      className="tooltip-with-img"
                      keepTooltipOpenOnHover
                      selectableTextInTooltip
                      title={ssinTooltipTitle}>
                    <i className="fa fa-info-circle"></i>&nbsp;
                  </Tooltip>
                </span>
              </div>
            </div>
        )}

        <div className="form-group">
          <label className="control-label col-sm-4">
            {I18n.get().t("last_name_patient", {
              contact_designation: contactDesignation
            })}{" "}
            *
          </label>
          <div className="col-sm-6">
            <input
              className="form-control input-sm"
              type="text"
              onChange={this.setLastName}
              value={lastName}
              name="appointment[patient_attributes][last_name]"
              id="appointment_patient_attributes_last_name"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4">
            {I18n.get().t("first_name_patient", {
              contact_designation: contactDesignation
            })}{" "}
            *
          </label>
          <div className="col-sm-6">
            <input
              className="form-control input-sm"
              type="text"
              onChange={this.setFirstName}
              value={firstName}
              name="appointment[patient_attributes][first_name]"
              id="appointment_patient_attributes_first_name"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4">{I18n.get().t("mobile")} *</label>
          <div className="col-sm-6">
            <span style={{display: 'flex'}}>
              <input
                ref={c => (this._select = c)}
                type="text"
                className="form-control prefix input-sm"
                defaultValue={this.state.prefix}
              />
              <input
                className="form-control input-sm"
                type="text"
                onChange={this.setPhoneNumber}
                onBlur={this.validatePhoneNumber}
                value={phone}
              />
              <input
                type="hidden"
                value={phone_with_prefix}
                name="appointment[patient_attributes][phone_number]"
                id="appointment_patient_attributes_phone_number"
              />
            </span>
            {validationErrors.phone && (
              <p id="invalid-phone-number" className="help-block">
                <span className="fa fa-exclamation-circle" aria-hidden="true" />
                <span className="invalid-reason">{validationErrors.phone}</span>
              </p>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4">{I18n.get().t("email")} *</label>
          <div className="col-sm-6">
            <input
              className="form-control input-sm"
              type="text"
              onChange={this.setEmail}
              onBlur={this.validateEmail}
              value={email}
              name="appointment[patient_attributes][email]"
              id="appointment_patient_attributes_email"
            />
            {validationErrors.email && (
              <p id="invalid-email" className="help-block">
                <span className="fa fa-exclamation-circle" aria-hidden="true" />
                <span className="invalid-reason">{validationErrors.email}</span>
              </p>
            )}
          </div>
        </div>

        {birthdateOnPatientForm && (
          <div className="form-group">
            <label className="control-label col-sm-4">
              {I18n.get().t("birthdate")} *
            </label>
            <div className="col-sm-6">
              <CrossPlatformDatePicker
                name="appointment[patient_attributes][birthdate]"
                id="appointment_patient_attributes_birthdate"
                defaultValue={birthDate}
                onChange={this.setBirthDate}
              />
            </div>
          </div>
        )}

        {addressOnPatientForm && (
          <div className="form-group">
            <label className="control-label col-sm-4">
              {I18n.get().t("address")} *
            </label>
            <div className="col-sm-6">
              <input
                className="form-control input-sm"
                type="text"
                onChange={this.setAddress}
                value={address}
                name="appointment[patient_attributes][address]"
                id="appointment_patient_attributes_address"
                autoComplete="off"
              />
            </div>
          </div>
        )}

        <div className="form-group form-address">
          <label>{I18n.get().t("do_not_fill")}</label>
          <input type="text" name="country" autoComplete="off" />
        </div>

        {allowPatientNotes && (
          <div className="form-group">
            <label className="control-label col-sm-4">
              {I18n.get().t("notes")}
              {patientNotesAreMandatory && " *"}
            </label>
            <div className="col-sm-6">
              <textarea
                maxLength="200"
                className="form-control input-sm"
                onChange={this.setNotes}
                value={notes}
                name="appointment[patient_notes]"
                id="appointment_patient_notes"
              />
              <span className="description">
                {I18n.get().t("visit_purpose", { name: officialNameInASentence })}
              </span>
            </div>
          </div>
        )}

        {askPatientConsent && (
          <div className="form-group">
            <label className="control-label col-sm-4">
              {this.props.calendar.countryCode == "CH" ? I18n.get().t("ask_patient_consent_label_swiss") : I18n.get().t("ask_patient_consent_label")}
            </label>
            <div className="col-sm-8">
              <div className="checkbox">
                <input
                  className="awesome-checkbox"
                  type="checkbox"
                  checked={gdprAgreed}
                  onChange={this.setGDPR}
                />
                <label className="no-pointer"></label>
              </div>
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-6">
              <span className="description">
                {this.props.calendar.countryCode == "CH" ? I18n.get().t("ask_patient_consent_text_swiss") : I18n.get().t("ask_patient_consent_text")}
              </span>
            </div>
          </div>
        )}

        {isMedical && (
          <div className="form-group">
            <div className="col-sm-4"></div>
            <div className="col-sm-6">
              <span className="description">{I18n.get().t("disclaimer")}</span>
            </div>
          </div>
        )}

        <br />

        <input
          type={!this.state.isLoading ? "submit" : "hidden"}
          name="commit"
          className={`btn btn-primary btn-sm pull-right commit-appointment ${
            this.submitButtonIsEnabled ? "" : "disabled"
          }`}
          disabled={!this.submitButtonIsEnabled}
          value={I18n.get().t("confirm")}
        />
        {this.state.isLoading && (
          <div className="progenda-spinner"></div>
        )}

        <div className="clearfix" />
      </form>
    );
  }
}
