import { string, number, bool, shape } from "prop-types";
import Service from "./service.js";
import ProgendaContext from "shared/progenda_context";
import { getColors } from "shared/colors.js";
import PI from "services/progenda_interface.js";

const Calendar = function(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      this[prop] = obj[prop];
    }
  }
};

Calendar.parseCalendarHash = hash => {
  return new Calendar({
    ...hash,
    languages: hash.displayedLanguages,
    postcodes: hash.postcodes ? hash.postcodes.split(",") : []
  });
};

Calendar.parseCalendarsHash = calendars =>
  calendars.map(calendar => Calendar.parseCalendarHash(calendar));

Calendar.retrieve = slug => {
  return PI.get({ url: PI.calendarPath(slug) }).then(({ data }) => {
    return Calendar.parseCalendarHash(data.calendar);
  });
};

Calendar.prototype.retrieveServices = function() {
  this.newServicePromise =
    this.newServicePromise ||
    PI.get({
      url: PI.calendarServicesPath(this.slug)
    }).then(({ data }) => {
      Service.parseServicesHash(data.map(serviceObj => serviceObj.service));
    });
  return this.newServicePromise;
};

Calendar.prototype.retrieveSuggestions = function(serviceId, splitType) {
  return PI.get({
    url: PI.calendarSuggestionsPath({
      calendar_id: this.slug,
      service_id: serviceId,
      split_type: splitType
    })
  }).then(({ data }) =>
    data.suggestions.map(suggestionObj => suggestionObj.suggestion)
  );
};

Calendar.prototype.getSuggestedColors = function() {
  if (this.allowOldColors) {
    return getColors("newColors").concat(getColors("oldColors"));
  } else {
    return getColors("newColors");
  }
};

Calendar.prototype.loadServices = function() {
  /*TODO change the way it saved (from hash(id => service) to array), because we loose the services ordering */
  if (this.servicesPromise === undefined || this.services === undefined) {
    this.services = {};
    this.servicesPromise = new Promise(resolve => {
      let url = PI.calendarServicesPath(this.slug);
      if (ProgendaContext.getContext() === "ADMIN") {
        url = PI.adminCalendarServicesPath(this.slug);
      }

      PI.get({
        url
      }).then(({ data }) => {
        data.forEach(serviceContainer => {
          const { service } = serviceContainer;
          this.services[service.id] = {
            id: service.id,
            duration: service.duration,
            currentName: service.name,
            translation: service.name,
            color: service.color,
            instructions: service.instructions,
            label: service.label,
            resourceGroupId: service.resourceGroupId,
            trueTranslation: service.trueTranslation,
            atHomeByDefault: service.atHomeByDefault,
            teleconsultByDefault: service.teleconsultByDefault,
            sortIndex: service.sortIndex
          };
        });
        resolve(this.services);
      });
    });
  }
  return this.servicesPromise;
};

Calendar.loadUniqueMultipleServices = calendars => {
  const promises = [];
  const selectableServices = [];
  calendars.forEach(calendar => {
    promises.push(
      new Promise(resolve => {
        calendar.loadServices().then(services => {
          Object.values(services).forEach(service =>
            selectableServices.push(service)
          );
          resolve();
        });
      })
    );
  });
  return Promise.all(promises).then(() => {
    /* TODO: comment */
    const selectableServiceCodes = selectableServices
      .map(s => s.label)
      .filter((v, i, self) => self.indexOf(v) === i);
    const uniqueSelectableServices = selectableServiceCodes.map(
      code =>
        selectableServices.find(
          service => service.label === code && service.trueTranslation
        ) || selectableServices.find(service => service.label === code)
    );
    return uniqueSelectableServices;
  });
};

export default Calendar;

/**
 * TODO: In future add all the props of a calendar
 */
export const CalendarProps = shape({
  id: number,
  slug: string,
  firstName: string,
  lastName: string,
  schedule: ScheduleProps
});

/**
 * TODO: Add all the schedule props
 */
export const ScheduleProps = shape({
  id: number,
  averageTime: number,
  displayEnd: string,
  displayStart: string
});
