import React from "react";
var HelenaProButton = function (_a) {
    var label = _a.label, icon = _a.icon, buttonType = _a.buttonType, extraClass = _a.extraClass, _b = _a.type, type = _b === void 0 ? "button" : _b, onClick = _a.onClick, disabled = _a.disabled;
    var buttonClasses = {
        'primary': "btn-primary-green",
        'secondary': "btn-default alternate",
        'cancel': "btn-cancel",
        'delete': "btn-delete"
    };
    var buttonClass = buttonType ? buttonClasses[buttonType] || "btn-default" : "btn-default";
    return (React.createElement("button", { type: type, className: "btn " + buttonClass + " " + extraClass, onClick: onClick, disabled: disabled },
        React.createElement("span", null,
            React.createElement("span", { className: icon }),
            " \u00A0"),
        label));
};
export default HelenaProButton;
try {
    // @ts-ignore
    HelenaProButton.displayName = "HelenaProButton";
    // @ts-ignore
    HelenaProButton.__docgenInfo = { "description": "", "displayName": "HelenaProButton", "props": { "label": { "defaultValue": null, "description": "", "name": "label", "required": true, "type": { "name": "string" } }, "icon": { "defaultValue": null, "description": "", "name": "icon", "required": false, "type": { "name": "string" } }, "buttonType": { "defaultValue": null, "description": "", "name": "buttonType", "required": false, "type": { "name": "\"primary\" | \"secondary\" | \"cancel\" | \"default\" | \"delete\"" } }, "extraClass": { "defaultValue": null, "description": "", "name": "extraClass", "required": false, "type": { "name": "string" } }, "type": { "defaultValue": { value: "button" }, "description": "", "name": "type", "required": false, "type": { "name": "\"button\" | \"submit\"" } }, "onClick": { "defaultValue": null, "description": "", "name": "onClick", "required": false, "type": { "name": "React.MouseEventHandler<HTMLButtonElement>" } }, "disabled": { "defaultValue": null, "description": "", "name": "disabled", "required": false, "type": { "name": "boolean" } } } };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["app/javascript/admin/components/helena_pro/shared/HelenaProButton.tsx#HelenaProButton"] = { docgenInfo: HelenaProButton.__docgenInfo, name: "HelenaProButton", path: "app/javascript/admin/components/helena_pro/shared/HelenaProButton.tsx#HelenaProButton" };
}
catch (__react_docgen_typescript_loader_error) { }
