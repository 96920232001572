const parseSsin = (ssin) => {
  if (!ssin) {
    return {};
  }
  const ssinString = ssin.toString().replace(/[-.]/g, '');

  let year = ssinString.substring(0, 2);
  let month = ssinString.substring(2, 4);
  let day = ssinString.substring(4, 6);
  let hasMissingBirthDate = false;

  // Missing birth date:
  // If no information about the date of birth is known, the fictional date must be used.
  // 01001900 or 01002000 can be used.
  // https://www.ibz.rrn.fgov.be/fileadmin/user_upload/nl/rr/instructies/IT-lijst/IT000_Rijksregisternummer.pdf
  if ((!year || year === '00') && (!month || month === '00') && (!day || day === '00')) {
    year = '00';
    month = '00';
    day = '01';
    hasMissingBirthDate = true;
  }

  return {
    year,
    month,
    day,
    sequence: ssinString.substring(6, 9),
    control: ssinString.substring(9, 11),
    hasMissingBirthDate
  };
};

export default parseSsin;
