/* Update : 2019-08-23 09:00 */

var Assets = {
    
    "eID-verso-de-new.png": "/assets/eID-verso-de-new-39f101e5f0f973ead9861eedc39e3bc26e2eea3b1dca4b4d57e3cc9960a11f3f.png",
    
    "eID-verso-de-old.png": "/assets/eID-verso-de-old-5fd7c7cf788d6d12ce194562f842c76c0597c5aa494d7a15871cdd15ca91fc28.png",
    
    "eID-verso-fr-new.png": "/assets/eID-verso-fr-new-4436036ac57a5fa951ac8b1926af8fbe23b8e73899a8521b61f7e78737de70a0.png",
    
    "eID-verso-fr-old.png": "/assets/eID-verso-fr-old-420a530e03be4197beb4299103afcdbff3e0084ba854246f7272bf0ed0264030.png",
    
    "eID-verso-nl-new.png": "/assets/eID-verso-nl-new-31a638c7950c01d00a2df1e36a32e648f7a102136e4e9f537e4e536a93f71c98.png",
    
    "eID-verso-nl-old.png": "/assets/eID-verso-nl-old-74ebd08a8a906a31bb4fdd17187ea6369da704d8959433d7b5175a2e6cbdb2dd.png",
    
    "isi-front.png": "/assets/isi-front-da19410d07fe9342ad4b74dfb0698cbb15723a7943c52d4199b0dcd199b7bf23.png",
    
};

export default Assets;