class Step extends React.Component {
  constructor(props) {
    super(props);
  }

  hide() {
    this.props.onHide();
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default Step;
