import SelectFilter from "./select_filter.js";
import ProgendaUtils from "shared/progenda_utils.js";
import CalendarUtils from "shared/calendar_utils.js";

export default class FilterLanguages extends React.Component {
  state = { languages: [] };

  componentDidMount() {
    this.loadLanguages(this.props.selectedCalendars);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCalendars !== this.props.selectedCalendars) {
      this.loadLanguages(this.props.selectedCalendars);
    }
  }

  loadLanguages = calendars => {
    const languages = {};
    calendars.forEach(calendar => {
      calendar.languages.forEach(language => {
        languages[language.id] = { id: language.code, name: language.name };
      });
    });
    this.setState({ languages: Object.values(languages) });
  };

  toggleLanguage = language => {
    const { languageFilter, setLanguageFilter } = this.props;
    if (languageFilter.includes(language)) {
      setLanguageFilter(languageFilter.filter(l => l !== language));
    } else {
      setLanguageFilter([...languageFilter, language]);
    }
  };

  render() {
    return (
      <SelectFilter
        title={I18n.get().t("choose_languages")}
        resources={this.state.languages}
        selectedResourceIds={this.props.languageFilter}
        toggleResource={this.toggleLanguage}
      />
    );
  }
}
