import SelectFilter from "./select_filter.js";
import ProgendaUtils from "shared/progenda_utils.js";
import CalendarUtils from "shared/calendar_utils.js";

export default class FilterHours extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timesRanges: this.getHoursToDisplay(props.calendars)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendars !== this.props.calendars) {
      this.setState({
        timesRange: this.getHoursToDisplay(this.props.calendars)
      });
    }
  }

  getHoursToDisplay = calendars => {
    var displayStart = CalendarUtils.getDisplayStart(calendars);
    var displayEnd = CalendarUtils.getDisplayEnd(calendars);
    var hoursToDisplay = [];
    for (var i = 0; displayStart.isBefore(displayEnd); i++) {
      var hours = displayStart.add(1, "hours").hours();
      hoursToDisplay.push({
        id: hours,
        name:
          ProgendaUtils.dateFormat(
            moment()
              .set("hours", hours)
              .set("minutes", 0),
            "simple_time_format"
          ) +
          " - " +
          ProgendaUtils.dateFormat(
            moment()
              .set("hours", hours)
              .set("minutes", 0)
              .add(1, "hours"),
            "simple_time_format"
          )
      });
    }
    return hoursToDisplay;
  };

  toggleHour = hour => {
    const { hourFilter, setHourFilter } = this.props;
    if (hourFilter.includes(hour)) {
      setHourFilter(hourFilter.filter(h => h !== hour));
    } else {
      setHourFilter([...hourFilter, hour]);
    }
  };

  render() {
    return (
      <SelectFilter
        title={I18n.get().t("choose_hours")}
        resources={this.state.timesRanges}
        selectedResourceIds={this.props.hourFilter}
        toggleResource={this.toggleHour}
        useHelenaProDesign={this.props.useHelenaProDesign}
      />
    );
  }
}
