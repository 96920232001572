import bowser from "bowser";
import ProgendaUtils from "shared/progenda_utils.js";
import CalendarUtils from "shared/calendar_utils.js";
import CalendarUtilsReact from "shared/calendar_utils_react.js";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";

export default class AvailabilitiesCalendarComponent extends React.Component {
  constructor(props) {
    super(props);

    this._calendarRef = React.createRef();

    // /**
    //  * Next is used for all jquery calls code. This is used to keep track of the jquery object
    //  * for calendar
    //  */
    this._calendarJqueryDomInstance = null;

    /**
     * This becomes true after the events sources are added.
     * It's used to show the loading spinner
     */
    this._eventsAdded = false;

    this._daysNameArray = I18n.get().t("date.day_names").map(name =>
      name.substring(0, 2)
    );
  }

  /**
   * This getter returns the calendar API
   */
  get calendarApi() {
    if (
      !this._calendarApiCached &&
      this._calendarRef &&
      this._calendarRef.current
    ) {
      this._calendarApiCached = this._calendarRef.current.getApi();
    }
    return this._calendarApiCached;
  }

  /**
   * this returns the jquery dom element of the calendar.
   */
  get calendarJqueryElement() {
    if (
      this._calendarRef &&
      this._calendarRef.current &&
      !this._calendarJqueryDomInstance
    ) {
      this._calendarJqueryDomInstance = $(
        this._calendarRef.current.elRef.current
      );
    }
    return this._calendarJqueryDomInstance;
  }

  componentDidMount() {
    this.applyCSS();
  }

  applyCSS() {
    // add the left/right buttons
    CalendarUtilsReact.addToolbarButtons({
      onPrev: () => {
        this.calendarApi.prev();
        this.calendarRoutine();
      },
      onNext: () => {
        this.calendarApi.next();
        this.calendarRoutine();
      }
    });

    this.resizeCallback = () => {
      this.resizeCalendarHeader();
    };
    $(window).resize(this.resizeCallback);

    let stickyClassTimeout = 0;
    if (bowser.safari) {
      // add the sticky classes on safari a bit later. This is MANDATORY to avoid flickering on scroll
      stickyClassTimeout = 250;
    }
    if (ProgendaUtils.isMobile()) {
      // add the sticky classes on mobile a bit later. This is MANDATORY to avoid flickering on scroll
      stickyClassTimeout = 2000;
    }

    this._timeout = setTimeout(() => {
      this.calendarJqueryElement
          .find(".fc-toolbar-wrapper")
          .removeClass("sticky-on-top")
          .addClass("sticky-on-top");

      this.calendarJqueryElement
          .find(".fc-head-wrapper")
          .removeClass("sticky-on-top")
          .addClass("sticky-on-top");
    }, stickyClassTimeout);
  }

  componentWillUnmount() {
    $(window).off("resize", this.resizeCallback);
  }

  computeNextAvailability = () => {
    let target;
    this.calendarApi.getEvents().some(event => {
      if (
        this.calendarApi.view.currentStart < event.start &&
        (target == undefined || event.start <= target.start)
      ) {
        target = event;
        return true;
      }
    });
    return target;
  };

  calendarRoutine = modalIsDisplayed => {
    const {
      calendar: {
        noAvailabilitiesForWeekText,
        officialNameInASentence,
        phoneNumber
      }
    } = this.props;
    this.resizeCalendarHeader();
    $('a.fc-event').each(function() {
      $(this).attr('rel', 'nofollow');
    });
    const calendarGridParent = $(".fc-time-grid-container").parent();
    const customMessageNoResult = $(".custom-message-no-result");
    if (!this._eventsAdded) {
      if (!customMessageNoResult.length && !modalIsDisplayed) {
        // add a loading indicator, until we have the events
        calendarGridParent.prepend(
          "<div class='custom-message-no-result'><div class='no-result-box'><div class='progenda-spinner'></div></div></div>"
        );
      }
      return;
    }

    customMessageNoResult.remove();

    if (modalIsDisplayed) {
      calendarGridParent.prepend(
        "<div class='custom-message-no-result'></div>"
      );
    } else if (this.eventsThisWeek() === 0) {
      const nextAvailability = this.computeNextAvailability();
      let noResultText;
      if (nextAvailability) {
        noResultText =
          (ProgendaUtils.filterXSS(noAvailabilitiesForWeekText, false, false, true) ||
            I18n.get().t("no_availabilities_found_for_week")) +
          "<br /><br />" +
          I18n.get().t("next_availability") +
          ' <a id="jump-next-availability">' +
          ProgendaUtils.dateFormat(
            moment.utc(nextAvailability.start),
            "long_datetime_format_with_year"
          ) +
          "</a>";
      } else {
        let noAvailabilitiesFoundText = ProgendaUtils.filterXSS(
          I18n.get().t("no_availabilities_found", {
            name: officialNameInASentence
          })
        );
        if (phoneNumber && phoneNumber.toString().length) {
          noAvailabilitiesFoundText = `${noAvailabilitiesFoundText} ${I18n.get().t(
            "no_availabilities_found_phone_number",
            {
              phone: phoneNumber
            }
          )}`;
        }

        noResultText =
          ProgendaUtils.filterXSS(noAvailabilitiesForWeekText) ||
          noAvailabilitiesFoundText;
      }
      calendarGridParent.prepend(
        "<div class='custom-message-no-result'><div class='no-result-box alert alert-danger'><span><span class=\"fa fa-warning \"></span>&nbsp;&nbsp;" +
          noResultText +
          "</span></div></div>"
      );
      $("#jump-next-availability").click(() => {
        this.calendarApi.gotoDate(nextAvailability.start);
        this.calendarRoutine();
        this.scrollToEvent();
      });
    }
    this.applyCSS();
  };

  removeEvents = () => {
    this.calendarApi.batchRendering(() => {
      this.calendarApi.getEvents().forEach(event => event.remove());
    });
  };

  addEvents = suggestions => {
    /**
     * The latest version of full calendar needs dates or date strings as start/end params
     * so we parse the suggestions
     */
    const parsedSuggestions = suggestions.map(item => ({
      ...item,
      start: item.start.format(),
      end: item.end.format()
    }));

    this.calendarApi.addEventSource(parsedSuggestions);
    const { waitForServiceSelection, selectedServiceCodes } = this.props;
    if (waitForServiceSelection) {
      if (selectedServiceCodes.length > 0) {
        // show the loading spinner until a service was selected
        this._eventsAdded = true;
      }
    } else {
      this._eventsAdded = true;
    }
  };

  resizeCalendarHeader = () => {
    if (this.calendarJqueryElement) {
      if (!$(".fc-head-wrapper").length) {
        const fcHeadContainer = $(
          "<div class='fc-head-wrapper'><table></table></div>"
        );
        $(".fc-head")
          .closest(".fc-view")
          .prepend(fcHeadContainer);
        $(".fc-head").appendTo(fcHeadContainer.find("table"));
        this.calendarJqueryElement.find(".fc-head-wrapper").css({
          height: $(".fc-head").height(),
          top: $(".fc-toolbar").outerHeight(true)
        });
      }
      if (!$(".fc-toolbar-wrapper .fc-toolbar").length) {
        $(".fc-toolbar-wrapper").remove();
        const fcToolbarContainer = $("<div class='fc-toolbar-wrapper'></div>");
        $(".fc-toolbar")
          .parent()
          .prepend(fcToolbarContainer);
        $(".fc-toolbar").appendTo(fcToolbarContainer);

        this.calendarJqueryElement.find(".fc-head-wrapper").css({
          top: $(".fc-toolbar").outerHeight(true)
        });
      }
    }
  };

  eventsThisWeek = () => {
    let size = 0;
    $.each(this.calendarApi.getEvents(), (index, elem) => {
      if (
        this.calendarApi.view.currentStart < elem.start &&
        this.calendarApi.view.currentEnd > elem.end
      )
        size++;
    });
    return size;
  };

  scrollToEvent = () => {
    let height = this.minHeightEvent();
    if (height === null) {
      const customMessageNoResult = $(".custom-message-no-result");
      if (customMessageNoResult.length) {
        height = $(".custom-message-no-result").offset().top;
      }
      height -= $(window).height() / 2;
      window.scroll(0, height);
    } else if ($(document).width() > 991) {
      window.scroll(0, height);
    }
  };

  minHeightEvent = () => {
    let min = null;
    $(".fc-event").each((index, elem) => {
      let height = elem.offsetTop;
      if (min == null || min > height) min = height;
    });
    return min;
  };

  /**
   * This was needed instead of using `columnHeaderFormat="dd D"` because moment.js outputs
   * capital first letter when formatting a day with 2 letters.
   * E.g: in French for Monday it was showing Lu instead of lu
   */
  _renderColumnHeaderDate = date => {
    return `${this._daysNameArray[date.getDay()]} ${date.getDate()}`;
  };

  /**
   * This method updates the contents of each event item.
   * t's executed as soon as the event is rendered
   */
  _eventPositioned = ({ event, el }) => {
    if (event.isBackground === undefined || event.isBackground == false) {
      el.innerHTML =
        `<div class="appointment-suggestion moment-` +
        new Date(
          moment(event.start)
            .utc()
            .year(),
          moment(event.start)
            .utc()
            .month(),
          moment(event.start)
            .utc()
            .date(),
          moment(event.start)
            .utc()
            .hours(),
          moment(event.start)
            .utc()
            .minutes(),
          moment(event.start)
            .utc()
            .seconds(),
          moment(event.start)
            .utc()
            .milliseconds()
        ).getTime() /
          1000 +
        `"><span class="free"> 
        ${I18n.get().t("free")}
        </span>
        <span class="hour"> ${moment
          .utc(event.start)
          .locale(moment.locale())
          .format("HH")}
        </span>
        <span class="min">: ${moment
          .utc(event.start)
          .locale(moment.locale())
          .format("mm")}
        </span>
        </div>`;
    }
  };

  _datesRender = ({ view }) => {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    const {
      calendar: { maxDaysConstraint, minHoursConstraint }
    } = this.props;
    const maxDate = new Date(y, m, d + maxDaysConstraint);
    const minDate = new Date(y, m, d, minHoursConstraint);
    if (this.calendarApi !== undefined) {
      if (view.activeStart > maxDate) {
        this.calendarApi.gotoDate(maxDate);
      }
      if (view.activeEnd <= minDate) {
        this.calendarApi.gotoDate(minDate);
      }
    }
  };

  render() {
    const { calendar } = this.props;
    const { timeDateFormats } = CalendarUtilsReact;

    let slotDuration = CalendarUtils.computeSlotDuration(calendar.averageTime);

    return (
      <FullCalendar
        ref={this._calendarRef}
        defaultView="timeGridWeek"
        plugins={[timeGridPlugin, momentPlugin]} //timeGridPlugin -> needed for defaultView, momentPlugin -> needed for date formatting
        height="auto"
        header={{
          left: "prev",
          center: "title",
          right: "next"
        }}
        firstDay={1}
        allDaySlot={false}
        slotDuration={slotDuration}
        slotLabelInterval={CalendarUtils.computeSlot([calendar])}
        slotLabelFormat={timeDateFormats.fullCalendarTime}
        timeFormat={timeDateFormats.fullCalendarTime}
        minTime={CalendarUtils.getDisplayStart([calendar], true)}
        maxTime={CalendarUtils.getDisplayEnd([calendar], true)}
        columnHeaderFormat={'dd D'}
        titleFormat="{{{DD} MMMM} YYYY}"
        timeZone={calendar.timezone}
        selectable
        selectMirror
        editable={false}
        locale={I18n && I18n.get().locale ? I18n.get().locale : "en"}
        eventPositioned={this._eventPositioned}
        datesRender={this._datesRender}
      />
    );
  }
}
