import CalendarGroupSelect from "./calendar_group_select.js";
import Step from "shared/components/step.js";

export default class ServiceFilterGroupModal extends React.Component {
  selectServiceGroup(services) {
    this._modal.hide();
    this.props.selectServiceGroup(services);
  }
  render() {
    return (
      <Step ref={c => (this._modal = c)} onHide={this.props.onHide}>
        <div className="well select-service-center-search">
          <div className="text-center">
            {this.props.back && (
              <div>
                <div className="pull-left">
                  <a
                    className="btn btn-default btn-sm"
                    onClick={this.props.back}
                  >
                    {I18n.get().t("back")}
                  </a>
                </div>
                <div className="clearfix"></div>
              </div>
            )}
            <h3>
              {I18n.get().t("please_select_service_group", {
                service_filter_designation: I18n.get().t(
                  this.props.center.serviceFilterDesignation
                )
              })}
            </h3>
            <br />
            <br />
            <CalendarGroupSelect
              filterGroups={this.props.serviceFilters}
              filterGroupName="serviceCodes"
              selectFilterGroup={this.selectServiceGroup.bind(this)}
            />
          </div>
        </div>
      </Step>
    );
  }
}
