const Service = function(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      this[prop] = obj[prop];
    }
  }
};

Service.parseServiceHash = function(hash) {
  return new Service({
    id: hash.id,
    duration: hash.duration,
    translation: hash.name,
    color: hash.color,
    instructions: hash.instructions,
    label: hash.label,
    resourceGroupId: hash.resourceGroupId,
    trueTranslation: hash.trueTranslation,
    atHomeByDefault: hash.atHomeByDefault,
    teleconsultByDefault: hash.teleconsultByDefault
  });
};

Service.parseServicesHash = function(services) {
  return services.map(service => Service.parseServiceHash(service));
};

Service.sortServices = (serviceA, serviceB) => {
  /**
   * It's important to return 1, 0 or -1 for sorting to work in Chrome
   */
  if (serviceA.sortIndex !== undefined && serviceB.sortIndex !== undefined) {
    if (serviceA.sortIndex > serviceB.sortIndex) {
      return 1;
    } else if (serviceA.sortIndex < serviceB.sortIndex) {
      return -1;
    }
    return 0;
  } else {
    if (serviceA.createdAt > serviceB.createdAt) {
      return 1;
    } else if (serviceA.createdAt < serviceB.createdAt) {
      return -1;
    }
    return 0;
  }
};

export default Service;
