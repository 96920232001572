import MultipleServiceSelect from "./multiple_service_select.js";
import XSSFilter from "shared/components/xss_filter.tsx";
import Step from "shared/components/step.js";

export default class ServiceSelectModal extends React.Component {
  render() {
    return (
      <Step
        preventClick={true}
        ref={c => (this._modal = c)}
        onHide={this.props.onHide}
      >
        <div className="well select-service-center-search">
          <div className="text-center">
            {this.props.back && (
              <div className="pull-left">
                <a className="btn btn-default btn-sm" onClick={this.props.back}>
                  {I18n.get().t("back")}
                </a>
              </div>
            )}
            <h3>{I18n.get().t("please_select_service")}</h3>
            <br />
            {this.props.serviceSelectText && (
              <div>
                <XSSFilter content={this.props.serviceSelectText} />
                <br />
                <br />
              </div>
            )}
            <MultipleServiceSelect
              enableAppointmentsGroup={this.props.enableAppointmentsGroup}
              services={this.props.services}
              setSelectedServiceCodes={this.props.setSelectedServiceCodes}
              selectedServiceCodes={this.props.selectedServiceCodes}
            />
            <br />
          </div>
        </div>
      </Step>
    );
  }
}
