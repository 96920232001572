import ServiceSelectModal from "shared/components/suggesters/service_select_modal.js";
import AvailabilitiesCalendarComponent from "./calendar_component.js";
import XSSFilter from "shared/components/xss_filter.tsx";
import MultipleServiceSelect from "shared/components/suggesters/multiple_service_select.js";
import ProgendaUtils from "shared/progenda_utils.js";
import StepModal from "shared/components/step_modal.js";

export default class AvailabilitiesIndex extends React.Component {
  componentDidMount() {
    this.servicesChanged();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectableServices !== prevProps.selectableServices ||
      this.props.selectedServiceCodes !== prevProps.selectedServiceCodes
    ) {
      this.servicesChanged();
    }
    if (this.props.suggestions !== prevProps.suggestions) {
      this.suggestionsChanged();
    }
  }

  loadFromURL = () => {
    this.urlChecked = true;
    // Service id coming from the mini suggester
    const serviceId = ProgendaUtils.getFromURL("service_id");
    const selectedServiceCodes = this.props.selectableServices
      .filter(service => service.id == serviceId)
      .map(service => service.label);
    if (selectedServiceCodes.length > 0) {
      this.props.setSelectedServiceCodes(selectedServiceCodes);
      return true;
    }
    return false;
  };

  servicesChanged = () => {
    if (this.props.selectableServices !== null) {
      let loadedFromURL = null;
      if (!this.urlChecked) {
        loadedFromURL = this.loadFromURL();
      }
      if (
        (this.props.selectableServices.length > 0 &&
          this.props.selectedServiceCodes.length > 0) ||
        this.props.selectableServices.length === 0 ||
        loadedFromURL
      ) {
        this._calendar.calendarRoutine();
        this._serviceModal && this._serviceModal.hide();
      } else {
        this._calendar.calendarRoutine(true);
        this._serviceModal && this._serviceModal.show();
      }
    }
  };

  suggestionsChanged = () => {
    this._calendar.removeEvents();
    const suggestions = this.props.suggestions;
    suggestions.forEach(s => (s.end = s.stop));
    this._calendar.addEvents(suggestions);
    var modalIsDisplayed = !(
      this.props.selectableServices.length === 0 ||
      this.props.selectedServiceCodes.length > 0
    );
    this._calendar.calendarRoutine(modalIsDisplayed);
    this._calendar.scrollToEvent();
  };

  render() {
    if (this.props.selectableServices === null) {
      return <div />;
    }
    const calendar = this.props.selectedCalendars[0];
    const displayModal = this.props.selectableServices.length > 0;
    const displaySideBar =
      this.props.selectableServices.length > 0 || calendar.moreChoiceText;
    return (
      <div>
        <div className="row">
          {displaySideBar && (
            <div className="col-md-3 col-sm-12" id="lateral-panel-container">
              <div id="lateral-panel">
                {calendar.moreChoiceText && (
                  <div id="warning-box">
                    <div className="alert alert-warning text-center">
                      <strong>
                        <i className="fa fa-exclamation-triangle"></i>
                        <XSSFilter allowLinks={true} content={calendar.moreChoiceText} />
                      </strong>
                    </div>
                  </div>
                )}
                {this.props.selectableServices.length > 0 &&
                  this.props.selectedServiceCodes
                    .map(serviceCode =>
                      this.props.selectableServices.find(
                        service => service.label === serviceCode
                      )
                    )
                    .map(
                      service =>
                        service &&
                        service.instructions && (
                          <div id="warning-box">
                            <div className="alert alert-warning text-center">
                              <strong>
                                <i className="fa fa-exclamation-triangle"></i>
                                <XSSFilter allowLinks={true} content={service.instructions} />
                              </strong>
                            </div>
                          </div>
                        )
                    )}
                {this.props.selectableServices.length > 0 && (
                  <div className="well" id="service-select-well">
                    <div className="service-box">
                      <div className="text-center">
                        <div className="service-box-content-box">
                          <div className="service-box-content">
                            {I18n.get().t("please_select_service")}
                            <br />
                            <br />
                            {calendar.serviceSelectText && (
                              <div>
                                <XSSFilter
                                  content={calendar.serviceSelectText}
                                  allowLinks={true}
                                />
                                <br />
                              </div>
                            )}
                            <MultipleServiceSelect
                              enableAppointmentsGroup={
                                calendar.enableAppointmentsGroup
                              }
                              services={this.props.selectableServices}
                              setSelectedServiceCodes={
                                this.props.setSelectedServiceCodes
                              }
                              selectedServiceCodes={
                                this.props.selectedServiceCodes
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            id="calendar-container"
            className={`${
              displaySideBar ? "col-md-9" : "col-md-12"
            } col-sm-12 full-calendar-react`}
          >
            <AvailabilitiesCalendarComponent
              calendar={calendar}
              ref={c => (this._calendar = c)}
              selectedServiceCodes={this.props.selectedServiceCodes}
              waitForServiceSelection={displayModal}
            />
          </div>
        </div>

        {displayModal && (
          <StepModal
            ref={c => (this._serviceModal = c)}
            large={false}
            preventClick
          >
            <ServiceSelectModal
              enableAppointmentsGroup={calendar.enableAppointmentsGroup}
              serviceSelectText={calendar.serviceSelectText}
              services={this.props.selectableServices}
              setSelectedServiceCodes={this.props.setSelectedServiceCodes}
              selectedServiceCodes={this.props.selectedServiceCodes}
            />
          </StepModal>
        )}
      </div>
    );
  }
}
