import ReactDOM from "react-dom";

class AlertsComponent extends React.Component {
  state = { warnings: [], errors: [] };

  addWarning = message => {
    if (this.state.warnings.indexOf(message) === -1) {
      this.setState({ warnings: [...this.state.warnings, message] });
    }
  };

  addError = message => {
    if (this.state.errors.indexOf(message) === -1) {
      this.setState({ errors: [...this.state.errors, message] });
    }
  };

  removeWarning = message => {
    this.setState({
      warnings: this.state.warnings.filter(warning => warning !== message)
    });
  };

  removeError = message => {
    this.setState({
      errors: this.state.errors.filter(error => error !== message)
    });
  };

  render() {
    return (
      <div>
        {this.state.warnings.map(message => (
          <div
            key={message}
            className="alert alert-warning alert-dismissable alert-fixed"
          >
            <button
              type="button"
              className="close"
              onClick={() => {
                this.removeWarning(message);
              }}
            >
              ×
            </button>
            {message}
          </div>
        ))}
        {this.state.errors.map(message => (
          <div
            key={message}
            className="alert alert-danger alert-dismissable alert-fixed"
          >
            <button
              type="button"
              className="close"
              onClick={() => {
                this.removeError(message);
              }}
            >
              ×
            </button>
            {message}
          </div>
        ))}
      </div>
    );
  }
}

const Alerts = (() => {
  const element = React.createElement(AlertsComponent, {});
  let component;
  const mountPromise = new Promise((resolve, reject) => {
    $(() => {
      if ($("#alerts-container").length > 0) {
        component = ReactDOM.render(element, $("#alerts-container").get(0));
      }
      resolve();
    });
  });
  return {
    addWarning: message => {
      mountPromise.then(() => component.addWarning(message));
    },
    addError: message => {
      mountPromise.then(() => component.addError(message));
    },
    removeWarning: message => {
      mountPromise.then(() => component.removeWarning(message));
    },
    removeError: message => {
      mountPromise.then(() => component.removeError(message));
    }
  };
})();

window.addEventListener("load", () => {
  const updateOnlineStatus = event => {
    if (navigator.onLine) {
      Alerts.removeError(I18n.get().t("notification_offline"));
    } else {
      Alerts.addError(I18n.get().t("notification_offline"));
    }
  };
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
  if ($("#preload-notice").get(0)) {
    Alerts.addWarning(JSON.parse($("#preload-notice").text()));
  }
  if ($("#preload-alert").get(0)) {
    Alerts.addError(JSON.parse($("#preload-alert").text()));
  }
});

export default Alerts;
