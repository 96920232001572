let _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */

(function() {
    let domain = window.location.hostname;
    let u, siteId;

    if (domain === 'localhost' || domain === '127.0.0.1') {
        u = "//localhost:8088/";
        siteId = 1;
    } else if (domain === "qa.progenda.be" || domain === "calendar-qa.helenapro.care") {
        u = "//matomo-qa.helena.care/";
        siteId = 22;
    }else if (domain === "acc.progenda.be" || domain === "calendar-acc.helenapro.care"){
        u = "//matomo-acc.careconnect.be/";
        siteId = 18;
    }else {
        u = "//matomo.careconnect.be/";
        siteId = 17;
    }

    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', siteId]);
    let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();