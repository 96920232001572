import SelectFilter from "./select_filter.js";
import ProgendaUtils from "shared/progenda_utils.js";

export default class FilterDays extends React.Component {
  constructor(props) {
    super(props);

    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push({
        id: i + 1,
        name: ProgendaUtils.dateFormat(
          moment()
            .startOf("isoWeek")
            .add(i, "days"),
          "day_of_week_format"
        )
      });
    }

    this.state = { weekDays };
  }

  toggleDay = day => {
    const { dayFilter, setDayFilter } = this.props;
    if (dayFilter.includes(day)) {
      setDayFilter(dayFilter.filter(d => d !== day));
    } else {
      setDayFilter([...dayFilter, day]);
    }
  };

  render() {
    return (
      <SelectFilter
        title={I18n.get().t("choose_days")}
        resources={this.state.weekDays}
        selectedResourceIds={this.props.dayFilter}
        toggleResource={this.toggleDay}
        useHelenaProDesign={this.props.useHelenaProDesign}
      />
    );
  }
}
