import unFormatSsin from '../formatting/unFormat';
import parseSsin from '../parsing/parse';
import isBisNumber from './isBisNumber';

const getBisNumberMonth = (bis) => {
  const value = unFormatSsin(bis);
  if (!value || !isBisNumber(value)) {
    return undefined;
  }

  // Reduce month if is bis number
  const { month } = parseSsin(value);

  let modifiedMonth = month;
  if (modifiedMonth >= 40) {
    modifiedMonth -= 40;
  } else if (modifiedMonth >= 20) {
    modifiedMonth -= 20;
  } else {
    modifiedMonth = month;
  }
  return modifiedMonth;
};

export default getBisNumberMonth;
