import parseSsin from '../parsing/parse';

const formatSsin = (ssin) => {
  if (!ssin || ssin === '') {
    return undefined;
  }

  const { year, month, day, sequence, control } = parseSsin(ssin);

  const date = `${year}.${month}.${day}`;
  return `${date}-${sequence}.${control}`;
};

export default formatSsin;
