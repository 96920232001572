/* Update : 2021-02-11 09:00 */
let Secrets = (function(){
    function get() {
        var secrets = {}
        if ($("#preload-secrets-google-maps").get(0)) {
            secrets["googleMapsClientKey"] = JSON.parse($("#preload-secrets-google-maps").text());
        }
        if ($("#preload-secrets-scheduler").get(0)) {
            secrets["schedulerKey"] = JSON.parse($("#preload-secrets-scheduler").text())
        }
        if ($("#preload-secrets-stripe").get(0)) {
            secrets["stripePublishableKey"] = JSON.parse($("#preload-secrets-stripe").text())
        }
        if ($("#preload-secrets-pusher").get(0)) {
            secrets["pusherKey"] = JSON.parse($("#preload-secrets-pusher").text())
        }
        if ($("#preload-secrets-recaptcha").get(0)) {
            secrets["recaptchaSoftPublicKey"] = JSON.parse($("#preload-secrets-recaptcha").text())
        }
        return secrets
    }
    var secrets = "";

    return {
        get: function(){
            if (!secrets) {
                secrets = new get();
            }
            return secrets;
        }
    };
})()

export default Secrets;
