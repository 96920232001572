import { string, bool } from "prop-types";
import ProgendaUtils from "shared/progenda_utils.js";

export default class LinkTo extends React.Component {
  static propTypes = {
    className: string,
    path: string.isRequired,
    text: string.isRequired,
    disableLongPress: bool,
    method: string
  };

  static defaultProps = {
    className: "btn btn-sm btn-primary pull-right",
    method: undefined
  };

  constructor(props) {
    super(props);

    this._formRef = React.createRef();

    this.state = {
      loading: false,
      csrfToken: ""
    };
  }

  componentDidMount() {
    const { method } = this.props;
    if (method && method.toLowerCase() === "post") {
      const csrfToken = ProgendaUtils.getCSRFToken();
      this.setState({ csrfToken });
    }
  }

  clicked = e => {
    if (this.state.loading) {
      e.preventDefault();
      return false;
    }

    this.setState({ loading: true });

    if (this._formRef && this._formRef.current) {
      this._formRef.current.submit();
      e.preventDefault();
    }
  };

  /**
   * This is a public method which can be called from outside this component to re-enable the button
   * Because in all situations we do a page refresh after clicking on this button there's no need to automatically
   * switch the loading state to false.
   * So finishedLoading method is for special situations where we don't refresh the page after clicking on the button - more in the future
   */
  finishedLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { className, path, text, method } = this.props;

    if (method && method.toLowerCase() === "post") {
      /**
       * If the prop method is "post", the rails button was adding data data-method="post" attribute on the link.
       * When a link element has this attribute onClick event is no longer executed, so I can't control the
       * disabled state of the button anymore.
       * To fix this issue, I'm injecting a small form which has 2 hidden inputs, _method & authenticity_token exactly
       * the same form params which were sent by the pure rails button with data-method="post"
       */
      return (
        <React.Fragment>
          <form method="POST" action={path} ref={this._formRef}>
            <input type="hidden" name="_method" value="post" readOnly={true} />
            <input
              type="hidden"
              name="authenticity_token"
              value={this.state.csrfToken}
              readOnly={true}
            />

            <a
              className={`${className} ${"save-btn"} ${
                this.state.loading ? "disabled" : ""
              } ${this.props.disableLongPress ? "disable-long-press" : ""}`}
              href={path}
              onClick={this.clicked}
              rel={method ? "nofollow" : undefined}
            >
              {text}
            </a>
          </form>
        </React.Fragment>
      );
    }

    return (
      <a
        className={`${className} ${this.state.loading ? "disabled" : ""}`}
        href={path}
        onClick={this.clicked}
      >
        {text}
      </a>
    );
  }
}
