import ReactTurbolinks from "patient/react_turbolinks.js";
import Calendar from "shared/models/calendar.js";
import Appointment from "shared/models/appointment.js";
import AppointmentCancellationPage from "patient/components/appointments/cancellation/index.js";

export default () => {
  const container = document.getElementById("new_patient_cancellation");
  if (container) {
    const calendar = Calendar.parseCalendarHash(
      JSON.parse(document.getElementById("preloaded-calendar").text)
    );

    let appointment = null;
    const preloadedAppointment = document.getElementById(
      "preloaded-appointment"
    );
    let type = "appointmentNotReachable";
    if (preloadedAppointment) {
      appointment = Appointment.parseAppointmentHash(
        JSON.parse(preloadedAppointment.text)
      );
      type = "appointmentCancellationConfirmation";
      if (appointment.canceled) {
        type = "appointmentAlreadyCanceled";
      } else if (!appointment.canCancel) {
        type = "appointmentCancellationNotAllowed";
      }
    }

    ReactTurbolinks.mountComponent(
      AppointmentCancellationPage,
      { type, calendar, appointment },
      container
    );
  }
};
