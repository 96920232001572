import SelectFilter from "./select_filter.js";

export default class FilterSpecialities extends React.Component {
  state = { specialities: [] };

  componentDidMount() {
    this.loadSpecialities(this.props.selectedCalendars);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCalendars !== this.props.selectedCalendars) {
      this.loadSpecialities(this.props.selectedCalendars);
    }
  }

  loadSpecialities = calendars => {
    const specialities = {};
    calendars.forEach(calendar => {
      calendar.specialities.forEach(speciality => {
        specialities[speciality.id] = {
          id: speciality.id,
          name: speciality.name
        };
      });
    });

    this.setState({
      specialities: Object.values(specialities).sort(
        (speciality1, speciality2) =>
          speciality1.name > speciality2.name ? 1 : -1
      )
    });
  };

  toggleSpeciality = speciality => {
    const { specialityFilter, setSpecialityFilter } = this.props;
    if (specialityFilter.includes(speciality)) {
      setSpecialityFilter(specialityFilter.filter(s => s !== speciality));
    } else {
      setSpecialityFilter([...specialityFilter, speciality]);
    }
  };

  render() {
    return (
      <SelectFilter
        title={I18n.get().t("choose_specialities")}
        resources={this.state.specialities}
        selectedResourceIds={this.props.specialityFilter}
        toggleResource={this.toggleSpeciality}
      />
    );
  }
}
