/* Update : 2020-05-15 09:00 */
let Languages = (function(){
  function get() {
    if ($("#preload-languages").get(0)) {
      var languages = JSON.parse($("#preload-languages").text())
      languages.getName = function(code) {
        return languages.find(function(language) {
          return language.code === code;
        }).name;
      };
      languages.getById = function(id) {
        return languages.find(function(language) {
          return language.id === id;
        });
      };
      languages.getFlagCode = function(code) {
        return languages.find(function(language) {
          return language.code === code;
        }).flagCode;
      };
      languages.getLanguagesForUsers = function() {
        return languages.filter(language => ["fr", "nl", "en"].includes(language.code));
      }
      return languages
    }
  }
  var languages = "";

  return {
    get: function(){
      if (!languages) {
        languages = new get();
      }
      return languages;
    }
  };
})()

export default Languages;
