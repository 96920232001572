import Mailcheck from "mailcheck";

Mailcheck.defaultDomains.push(
  "gmail.com",
  "hotmail.com",
  "hotmail.fr",
  "hotmail.be",
  "live.be",
  "live.fr",
  "outlook.com",
  "outlook.be",
  "outlook.fr",
  "skynet.be",
  "yahoo.fr",
  "yahoo.com",
  "yahoo.co.uk",
  "yahoo.be",
  "msn.com",
  "telenet.be",
  "me.com",
  "icloud.com",
  "voo.be",
  "scarlet.be",
  "ec.europa.eu",
  "belgacom.net",
  "bnpparibasfortis.com",
  "laposte.net",
  "orange.fr",
  "europarl.europa.eu",
  "yahoo.it",
  "brutele.be",
  "base.be",
  "ymail.com",
  "ulb.ac.be",
  "yahoo.es",
  "free.fr",
  "wanadoo.fr",
  "mac.com",
  "eeas.europa.eu",
  "ep.europa.eu",
  "gmx.de",
  "diplobel.fed.be",
  "web.de",
  "libero.it",
  "live.com",
  "tvcablenet.be",
  "aol.com",
  "uclouvain.be",
  "proximus.be",
  "gmx.net",
  "consilium.europa.eu",
  "mail.com",
  "hotmail.it",
  "eeb4.eu",
  "mail.be",
  "swing.be",
  "pt.lu",
  "gmx.com",
  "vgz-co.be",
  "student.uclouvain.be",
  "hotmail.co.uk",
  "ing.be",
  "yahoo.gr",
  "minfin.fed.be",
  "bnpparibas.com",
  "pandora.be",
  "rtbf.be"
);
