import HField from "./h_field.js";
import InputGroup from "shared/components/input_group.js";

const HInput = props => {
  const {
    helper,
    layout,
    contactDesignation,
    tooltipFor,
    label,
    flag,
    labelClass,
    fieldClass,
    inputGroupLabel,
    type,
    disabled,
    autoComplete,
    defaultValue,
    value,
    onChange,
    readOnly,
    params,
  } = props;
  var id = props.id || (helper ? helper.computeId(props.for) : undefined);
  var name = props.name || (helper ? helper.computeName(props.for) : undefined);

  return (
    <HField
      for={props.for}
      htmlFor={id}
      layout={layout}
      contactDesignation={contactDesignation}
      tooltipFor={tooltipFor}
      label={label}
      flag={flag}
      labelClass={labelClass}
      fieldClass={fieldClass}
      readOnly={readOnly}
    >
      <div className={inputGroupLabel ? "input-group" : ""}>
        <InputGroup
          label={inputGroupLabel}
          className={
            "form-control input-sm " +
            (type === "number" ? "input-small-width" : "")
          }
          type={type || "text"}
          disabled={disabled}
          autoComplete={autoComplete || "disabled"}
          id={id}
          name={name}
          defaultValue={defaultValue}
          originalvalue={defaultValue}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          {...params}
        />
      </div>
    </HField>
  );
};

export default HInput;
