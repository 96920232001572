// to delete when stopping Edge 16 support

/**
 *
 *
 * @author Jerry Bendy <jerry@icewingcc.com>
 * @licence MIT
 *
 */

(function(self) {
  "use strict";

  if (self.URLSearchParams) {
    return;
  }

  var __URLSearchParams__ = "__URLSearchParams__",
    prototype = URLSearchParams.prototype,
    iterable = !!(self.Symbol && self.Symbol.iterator);

  /**
   * Make a URLSearchParams instance
   *
   * @param {object|string|URLSearchParams} search
   * @constructor
   */
  function URLSearchParams(search) {
    search = search || "";

    this[__URLSearchParams__] = {};

    // support construct object with another URLSearchParams instance
    if (search instanceof URLSearchParams) {
      search = search.toString();
    }

    if (typeof search === "object") {
      for (var i in search) {
        if (search.hasOwnProperty(i)) {
          var str =
            typeof search[i] === "string"
              ? search[i]
              : JSON.stringify(search[i]);
          this.append(i, str);
        }
      }
    } else {
      // remove first '?'
      if (search.indexOf("?") === 0) {
        search = search.slice(1);
      }

      var pairs = search.split("&");
      for (var j = 0; j < pairs.length; j++) {
        var value = pairs[j],
          index = value.indexOf("=");

        if (-1 < index) {
          this.append(
            decode(value.slice(0, index)),
            decode(value.slice(index + 1))
          );
        }
      }
    }
  }

  /**
   * Appends a specified key/value pair as a new search parameter.
   *
   * @param {string} name
   * @param {string} value
   */
  prototype.append = function(name, value) {
    var dict = this[__URLSearchParams__];
    if (name in dict) {
      dict[name].push("" + value);
    } else {
      dict[name] = ["" + value];
    }
  };

  /**
   * Deletes the given search parameter, and its associated value,
   * from the list of all search parameters.
   *
   * @param {string} name
   */
  prototype.delete = function(name) {
    delete this[__URLSearchParams__][name];
  };

  /**
   * Returns the first value associated to the given search parameter.
   *
   * @param {string} name
   * @returns {string|null}
   */
  prototype.get = function(name) {
    var dict = this[__URLSearchParams__];
    return name in dict ? dict[name][0] : null;
  };

  /**
   * Returns all the values association with a given search parameter.
   *
   * @param {string} name
   * @returns {Array}
   */
  prototype.getAll = function(name) {
    var dict = this[__URLSearchParams__];
    return name in dict ? dict[name].slice(0) : [];
  };

  /**
   * Returns a Boolean indicating if such a search parameter exists.
   *
   * @param {string} name
   * @returns {boolean}
   */
  prototype.has = function(name) {
    return name in this[__URLSearchParams__];
  };

  /**
   * Sets the value associated to a given search parameter to
   * the given value. If there were several values, delete the
   * others.
   *
   * @param {string} name
   * @param {string} value
   */
  prototype.set = function set(name, value) {
    this[__URLSearchParams__][name] = ["" + value];
  };

  /**
   *
   *
   * @param {function} callback
   * @param {object} thisArg
   */
  prototype.forEach = function(callback, thisArg) {
    var dict = this[__URLSearchParams__];
    Object.getOwnPropertyNames(dict).forEach(function(name) {
      dict[name].forEach(function(value) {
        callback.call(thisArg, value, name, this);
      }, this);
    }, this);
  };

  /**
   * Returns a string containg a query string suitable for use in a URL.
   *
   * @returns {string}
   */
  prototype.toString = function() {
    var dict = this[__URLSearchParams__],
      query = [],
      i,
      key,
      name,
      value;
    for (key in dict) {
      name = encode(key);
      for (i = 0, value = dict[key]; i < value.length; i++) {
        query.push(name + "=" + encode(value[i]));
      }
    }
    return query.join("&");
  };

  /**
   * Returns an iterator allowing to go through all keys of
   * the key/value pairs contained in this object.
   *
   * @returns {function}
   */
  prototype.keys = function() {
    var items = [];
    this.forEach(function(item, name) {
      items.push([name]);
    });
    return makeIterator(items);
  };

  /**
   * Returns an iterator allowing to go through all values of
   * the key/value pairs contained in this object.
   *
   * @returns {function}
   */
  prototype.values = function() {
    var items = [];
    this.forEach(function(item) {
      items.push([item]);
    });
    return makeIterator(items);
  };

  /**
   * Returns an iterator allowing to go through all key/value
   * pairs contained in this object.
   *
   * @returns {function}
   */
  prototype.entries = function() {
    var items = [];
    this.forEach(function(item, name) {
      items.push([name, item]);
    });
    return makeIterator(items);
  };

  if (iterable) {
    prototype[self.Symbol.iterator] = prototype.entries;
  }

  function encode(str) {
    var replace = {
      "!": "%21",
      "'": "%27",
      "(": "%28",
      ")": "%29",
      "~": "%7E",
      "%20": "+",
      "%00": "\x00"
    };
    return encodeURIComponent(str).replace(/[!'\(\)~]|%20|%00/g, function(
      match
    ) {
      return replace[match];
    });
  }

  function decode(str) {
    return decodeURIComponent(str.replace(/\+/g, " "));
  }

  function makeIterator(arr) {
    var iterator = {
      next: function() {
        var value = arr.shift();
        return { done: value === undefined, value: value };
      }
    };

    if (iterable) {
      iterator[self.Symbol.iterator] = function() {
        return iterator;
      };
    }

    return iterator;
  }

  self.URLSearchParams = URLSearchParams;

  self.URLSearchParams.polyfill = true;
})(
  typeof global !== "undefined"
    ? global
    : typeof window !== "undefined"
    ? window
    : this
);
