const unFormatSsin = (ssin) => {
  if (!ssin) {
    return undefined;
  }
  let ssinString = ssin.toString();
  if (ssinString.length >= 11) {
    ssinString = ssinString.replace(/[-.]/g, '');
  }
  if (ssinString.length === 11 && /\d+/.test(ssinString)) {
    return ssinString;
  }
  return undefined;
};

export default unFormatSsin;
