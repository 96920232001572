var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useRef } from "react";
import Inputmask from "inputmask";
import moment from "moment";
var CrossPlatformDatePicker = function (props) {
    var _a = useState({
        android: {
            day: null,
            month: 1,
            year: null
        }
    }), state = _a[0], setState = _a[1];
    var inputmask = useRef(null);
    // initialEffectOnAndroid -> is used to prevent callOnChangePropForAndroid immediately after the component is rendered
    var initialEffectOnAndroid = useRef(true);
    useEffect(function () {
        if (inputmask.current) {
            /**
             * If this component has an onChange prop, add the onKeyDown callback in inputmask options
             */
            var inputMaskOptions = {};
            if (typeof props.onChange === "function") {
                inputMaskOptions.onKeyDown = function (e) {
                    setTimeout(function () {
                        props.onChange && props.onChange(e.target.value);
                    }, 0);
                };
            }
            var im = new Inputmask("date", inputMaskOptions);
            im.mask(inputmask.current);
        }
        else {
            // only for android
            if (!initialEffectOnAndroid.current) {
                callOnChangePropForAndroid();
            }
            else {
                initialEffectOnAndroid.current = false;
            }
        }
    }, [state]);
    var onAndroidDayChange = function (e) {
        var value = e.target.value;
        setState({
            android: __assign(__assign({}, state.android), { day: value.toString().length > 0 ? value : null })
        });
    };
    var onAndroidMonthChange = function (e) {
        setState({
            android: __assign(__assign({}, state.android), { month: e.target.value })
        });
    };
    var onAndroidYearChange = function (e) {
        var value = e.target.value;
        setState({
            android: __assign(__assign({}, state.android), { year: value.toString().length > 0 ? value : null })
        });
    };
    var callOnChangePropForAndroid = function () {
        var onChange = props.onChange;
        if (typeof onChange !== "function") {
            return;
        }
        // if we have values for day, month & year we can call the onChange prop
        var _a = state.android, day = _a.day, month = _a.month, year = _a.year;
        if (day && month && year) {
            // transform month to string
            var monthString = month.toString();
            if (monthString.length === 1) {
                // add 0 in front of month
                monthString = "0".concat(monthString);
            }
            onChange("".concat(day, "/").concat(monthString, "/").concat(year));
        }
        else {
            onChange("");
        }
    };
    var appendFragmentToName = function (name, fragment) {
        if (name.substr(name.length - 1) === "]") {
            return name.substr(0, name.length - 1) + fragment + "]";
        }
        else {
            return name + fragment;
        }
    };
    return navigator.userAgent.match(/Android/i) || props.forceAndroid ? (React.createElement("div", null,
        React.createElement("input", { type: "number", id: props.id + "_3i", name: appendFragmentToName(props.name, "(3i)"), className: "form-control input-sm birthdate-day", autoComplete: "off", placeholder: "dd", onChange: onAndroidDayChange }),
        React.createElement("select", { id: props.id + "_2i", name: appendFragmentToName(props.name, "(2i)"), className: "form-control input-sm birthdate-month", onChange: onAndroidMonthChange }, moment.months().map(function (x, i) { return (React.createElement("option", { key: i, value: i + 1 }, x)); })),
        React.createElement("input", { type: "number", id: props.id + "_1i", name: appendFragmentToName(props.name, "(1i)"), className: "form-control input-sm birthdate-year", autoComplete: "off", placeholder: "yyyy", onChange: onAndroidYearChange }))) : (React.createElement("input", { type: "text", ref: inputmask, id: props.id, name: props.name, defaultValue: props.defaultValue, className: "form-control input-sm", autoComplete: "off" }));
};
CrossPlatformDatePicker.defaultProps = {
    defaultValue: ""
};
export default CrossPlatformDatePicker;
try {
    // @ts-ignore
    crossplatformdatepicker.displayName = "crossplatformdatepicker";
    // @ts-ignore
    crossplatformdatepicker.__docgenInfo = { "description": "", "displayName": "crossplatformdatepicker", "props": { "id": { "defaultValue": null, "description": "", "name": "id", "required": true, "type": { "name": "string" } }, "name": { "defaultValue": null, "description": "", "name": "name", "required": true, "type": { "name": "string" } }, "defaultValue": { "defaultValue": { value: "" }, "description": "", "name": "defaultValue", "required": false, "type": { "name": "string" } }, "onChange": { "defaultValue": null, "description": "", "name": "onChange", "required": false, "type": { "name": "(string: any) => void" } }, "forceAndroid": { "defaultValue": null, "description": "", "name": "forceAndroid", "required": false, "type": { "name": "boolean" } } } };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["app/javascript/shared/components/cross_platform_date_picker.tsx#crossplatformdatepicker"] = { docgenInfo: crossplatformdatepicker.__docgenInfo, name: "crossplatformdatepicker", path: "app/javascript/shared/components/cross_platform_date_picker.tsx#crossplatformdatepicker" };
}
catch (__react_docgen_typescript_loader_error) { }
