import React, { useEffect, useState } from "react";
import AppointmentStatusToggler from "./appointment_status_toggler";
import AppointmentStatusLogo from "./appointment_status_logo";
import PI from "../../../services/progenda_interface";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import camelize from "camelize";
import CalendarUtils from "../../calendar_utils";

const AppointmentStatusBanner = ({appointment, calendar, onCurrentStatusUpdate, currentStatus }) => {
    if(!appointment){
        return null;
    }
    const [time, setTime] = useState(moment());
    const center = calendar.center
    const [bannerIsOpen, setBannerIsOpen] = useState(true);
    const [showExtraStatusTogglerButtons, setshowExtraStatusTogglerButtons] = useState(false);
    const [currentAppointment, setAppointment] = useState(appointment);
    const toggleBanner = () => setBannerIsOpen(!bannerIsOpen)
    const toggleExtraTogglers = () => setshowExtraStatusTogglerButtons(!showExtraStatusTogglerButtons)
    const appointmentStart = appointment.start*1000;
    const appointmentStop = appointment.stop*1000;

    if((time.clone().startOf('day').isBefore(moment(appointmentStart).startOf('day')) || time.clone().startOf('day').isAfter(moment(appointmentStop).startOf('day'))) ||
        !center.showStatus ||
        currentStatus === 'cancelled'
    ){
        return null;
    }


    useEffect(() => {
        const interval = setInterval(() => {
            onCurrentStatusUpdate(CalendarUtils.getCurrentStatus(currentAppointment, center))
            setTime(moment())
        }, 1000);
        return () => clearInterval(interval);
    }, [currentAppointment]);

    const statusToShow = {
        'booked': [
            'arrived',
            'no_show',
            ...(center.showStatusInProgress ? ['in_progress'] : []),
            ...(center.showStatusArrivedLate ? ['arrived_late'] : []),
            ...(center.showStatusDone ? ['is_done'] : [])],
        'late': [
            ...(center.showStatusArrivedLate ? ['arrived_late'] : []),
            'no_show',
            'arrived',
            ...(center.showStatusInProgress ? ['in_progress'] : []),
            ...(center.showStatusDone ? ['is_done'] : []),
        ],
        'arrived': [...(center.showStatusInProgress ? ['in_progress'] : []), ...(center.showStatusDone ? ['is_done'] : [])],
        'arrived_late': [...(center.showStatusInProgress ? ['in_progress'] : []), ...(center.showStatusDone ? ['is_done'] : [])],
        'in_progress': [...(center.showStatusDone ? ['is_done'] : [])],
        'cancelled': [],
        'is_done': [],
        'no_show': []
    }

    const labelTogglerToShow = {
        arrived: I18n.get().t('patient_arrived',{contact_designation: I18n.get().t(center.contactDesignation)}),
        no_show: I18n.get().t('patient_no_show',{contact_designation: I18n.get().t(center.contactDesignation)}),
        arrived_late: I18n.get().t('patient_arrived_late',{contact_designation: I18n.get().t(center.contactDesignation)}),
        in_progress: I18n.get().t('appointment_in_progress'),
        is_done: I18n.get().t('appointment_finished')
    }


    const updateStatus = (newStatus) => {
        const delay = new Promise(resolve => setTimeout(resolve, 500));
        const apiRequest = PI.put({
            url: PI.adminCalendarAppointmentPath(calendar.slug, appointment.id, {
                appointment: {
                    appointment_status_attributes: {
                        [newStatus || currentStatus]: newStatus ? true : null
                    }
                }
            })
        });

        Promise.all([apiRequest, delay]).then(([apiResponse]) => {
            const data = apiResponse.data;
            setAppointment(data);
            onCurrentStatusUpdate(data.currentStatus);
        });
    }

    const statusTime = currentAppointment[camelize(currentStatus === 'arrived' ? 'patient_arrived_at' : currentStatus)]


    const createTimeLabel = () => {
        let returnTimeLabel;
        let label = I18n.get().t('appointment_statuses_' + currentStatus);

        if(currentStatus === 'late' || currentStatus === 'booked') {
            let appointmentTime = appointmentStart;
            const differenceInMinutes = Math.floor(((time.unix() * 1000 - appointmentTime) / 1000 / 60));

            returnTimeLabel = moment(appointmentTime).format("HH:mm");

            if (differenceInMinutes > 0 && currentStatus !== 'booked') {
                returnTimeLabel += " + " + differenceInMinutes + "'";
            }
        }else{
            returnTimeLabel =  moment(statusTime*1000).format("HH:mm");
        }
        returnTimeLabel = <span className='time-label'>{returnTimeLabel}</span>

        if (currentStatus === 'booked') {
            returnTimeLabel = (
                <>
                    {label}
                    {returnTimeLabel}
                </>
            );
        } else if (currentStatus === 'no_show') {
            returnTimeLabel = label;
        } else {
            returnTimeLabel = (
                <>
                    {returnTimeLabel}
                    {label}
                </>
            );
        }
        return returnTimeLabel;
    }

    return (
        <div className={`status-banner ${currentStatus}-light-bg-color`}>
            <div className="status-banner-header">
                <div className={`title-badge ${currentStatus}-bg-color`}>
                    <AppointmentStatusLogo status={currentStatus}
                                           label={[
                                               createTimeLabel(),
                                               <i className={'undo-icon fa fa-undo'} onClick={(event) => {event.stopPropagation(); updateStatus()}}/>
                                           ]}
                                           showBgColor={true}
                    />
                </div>
                {statusToShow[currentStatus].length > 0 &&
                    <i className={`collapse-icon ${currentStatus}-color fa fa-chevron-${bannerIsOpen ? 'down' : 'up'}`} onClick={toggleBanner}/>
                }
            </div>
            {statusToShow[currentStatus].length > 0 &&
                <TransitionGroup key={currentStatus}
                                 className={`status-banner-body ${bannerIsOpen ? 'expanded-all' : 'collapsed-all'} ${showExtraStatusTogglerButtons ? 'expanded' : 'collapsed'}`}>
                    {statusToShow[currentStatus].map((status, index) =>(
                        <CSSTransition
                            key={status}
                            classNames="toggle-container"
                            timeout={300}
                        >
                            <AppointmentStatusToggler
                                status={status}
                                updateStatus={updateStatus}
                                label={labelTogglerToShow[status]}
                            />
                        </CSSTransition>
                    ))}
                    {statusToShow[currentStatus].length > 1 && (
                        <div className={`button-toggle show-extra-status toggle-switch off`} onClick={toggleExtraTogglers}>
                            <i className={`fa ${showExtraStatusTogglerButtons ? 'fa-times' : 'fa-ellipsis-h'}`}></i>
                        </div>
                    )}
                </TransitionGroup>
            }
        </div>
    )
}
export default AppointmentStatusBanner;