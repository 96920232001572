import React, { useState } from "react";
var Checkbox = function (props) {
    var _isChecked = function () {
        if (props.checked !== undefined) {
            return props.checked;
        }
        if (props.defaultChecked !== undefined) {
            return props.defaultChecked;
        }
        return false;
    };
    var _a = useState(_isChecked()), checked = _a[0], setChecked = _a[1];
    React.useEffect(function () {
        if (props.checked != undefined && checked != props.checked) {
            setChecked(!checked);
        }
    }, [props.checked]);
    var _onChange = function (e) {
        setChecked(!checked);
        if (props.onChange) {
            props.onChange(e);
        }
    };
    var id = props.id || Math.random().toString(); // Ugly random ID
    var name = props.name || "";
    return (React.createElement("div", { className: "checkbox awesome-checkbox-parent " + (props.inline ? "checkbox-inline" : "") },
        React.createElement("input", { type: "hidden", name: name, defaultValue: props.emptyValue }),
        React.createElement("input", { className: "awesome-checkbox", type: "checkbox", value: props.value, disabled: props.disabled, id: id, name: name, onChange: _onChange, checked: checked }),
        React.createElement("label", { className: props.labelClassName, onClick: _onChange }, props.label)));
};
Checkbox.defaultProps = {
    disabled: false,
    defaultChecked: false,
    inline: false,
    value: "1",
    emptyValue: "0"
};
export default Checkbox;
try {
    // @ts-ignore
    checkbox.displayName = "checkbox";
    // @ts-ignore
    checkbox.__docgenInfo = { "description": "", "displayName": "checkbox", "props": { "checked": { "defaultValue": null, "description": "If the checkbox is checked or not", "name": "checked", "required": false, "type": { "name": "boolean" } }, "defaultChecked": { "defaultValue": { value: false }, "description": "If the checkbox is checked by default (starting state)", "name": "defaultChecked", "required": false, "type": { "name": "boolean" } }, "onChange": { "defaultValue": null, "description": "callback which is executed on change, it returns the status of the checkbox", "name": "onChange", "required": false, "type": { "name": "(e: any) => void" } }, "id": { "defaultValue": null, "description": "custom id, if not specified a random number is used as checkbox id", "name": "id", "required": false, "type": { "name": "string" } }, "name": { "defaultValue": null, "description": "html name of the input", "name": "name", "required": false, "type": { "name": "string" } }, "inline": { "defaultValue": { value: false }, "description": "if this is true the checkbox will be shown inline with other elements", "name": "inline", "required": false, "type": { "name": "boolean" } }, "emptyValue": { "defaultValue": { value: "0" }, "description": "the value for the hidden input", "name": "emptyValue", "required": false, "type": { "name": "string | number" } }, "value": { "defaultValue": { value: "1" }, "description": "value of the input", "name": "value", "required": false, "type": { "name": "string | number" } }, "disabled": { "defaultValue": { value: false }, "description": "If the checkbox is disabled", "name": "disabled", "required": false, "type": { "name": "boolean" } }, "labelClassName": { "defaultValue": null, "description": "custom class name for the label", "name": "labelClassName", "required": false, "type": { "name": "string" } }, "label": { "defaultValue": null, "description": "label text", "name": "label", "required": false, "type": { "name": "string" } } } };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["app/javascript/shared/components/checkbox.tsx#checkbox"] = { docgenInfo: checkbox.__docgenInfo, name: "checkbox", path: "app/javascript/shared/components/checkbox.tsx#checkbox" };
}
catch (__react_docgen_typescript_loader_error) { }
