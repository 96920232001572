import React, {useRef, useState} from "react";
import AppointmentStatusBanner from "./appointment_status_banner";
import ProgendaUtils from "../../progenda_utils";
import HelenaProButton from "../../../admin/components/helena_pro/shared/HelenaProButton";
import {getColor} from "../../colors";

const AppointmentPopup = ({ event, extendedProps, history, updateEventPopupOpened }) => {
    const popupRef = useRef(null);

    var startFormated =  ProgendaUtils.dateFormat(moment.tz(event.start, event.tz),
        "simple_time_format")
    var stopFormated = ProgendaUtils.dateFormat(
        moment.tz(event.end, event.tz),
        "simple_time_format"
    );
    var dateAppointment = ProgendaUtils.dateFormat(moment.tz(event.start, event.tz),
            "medium_date_format"
        )
    const appointment = extendedProps.appointment;
    const calendar = extendedProps.calendar;

    const appointmentLogo = () =>{
        if (appointment.atHome) {
            return 'fa fa-home'
        }
        else if(appointment.teleconsultation){
            return 'fa fa-video'
        }else{
            return 'fa fa-hospital'
        }
    }

    const hidePopup = () => {
        if(popupRef.current && popupRef.current.parentElement) {
            popupRef.current.parentElement.style.display = "none";
            updateEventPopupOpened(null);
        }
    }

    const editAppointment = () =>{
        history.push( event.url)
    }

    const [currentStatus, setCurrentStatus] = useState(extendedProps.appointment.currentStatus)

    const colorGray900 =  getColor('defaultPopupLabel', true)
    const labelBorderColor = appointment.color === '' || appointment.color === '#FFFFFF' ? colorGray900 : appointment.color;
    const labelColor = appointment.color === '' || appointment.color === '#FFFFFF' ? colorGray900 : '';

    return (
        <div ref={popupRef} className="appointment-popup">
            <div className='arrow'></div>
            <div className='popup-header'>
                <i className="fa fa-sharp fa-solid fa-quote-left"></i>
                <i className="fa fa-expand-alt" onClick={editAppointment}></i>
                <span className="appointment-title">{appointment.title}</span>

                <div className="popup-labels">
                    {currentStatus === 'no_show' &&
                        <div className="popup-label no_show-bg-color">
                            <span className='popup-label-logo'><i className='fas fa-eye-slash'/></span>
                            <span className='popup-label-text'>{I18n.get().t('no_show')}</span>
                        </div>
                    }
                    <div className="popup-label" style={{
                        backgroundColor: appointment.color,
                        borderColor: labelBorderColor,
                        color: labelColor,
                    }}>
                        <span className='popup-label-logo'><i className={appointmentLogo()}/></span>
                        {appointment.serviceName && <span className='popup-label-text'>{appointment.serviceName}</span>}
                    </div>
                </div>

            </div>

            <hr/>
            <AppointmentStatusBanner
                appointment={extendedProps.appointment}
                calendar={extendedProps.calendar}
                currentStatus={currentStatus}
                onCurrentStatusUpdate={setCurrentStatus}
            />
            <hr/>
            <div className="data-rows">
            <div className="data-row">
                    <i className="fa fa-calendar"></i>
                    <span className="data-text appointment-date">{dateAppointment}</span>
                </div>

                <div className="data-row">
                    <i className="fas fa-clock"></i>
                    <span className='data-text start-stop-date'>{startFormated} - {stopFormated}</span>
                </div>

                <div className="data-row">
                    <i className="far fa-user"></i>
                    <span className="data-text appointment-patient">{appointment.patientFullName}</span>
                </div>

                <div className="data-row">
                    <i className="fas fa-user-md"></i>
                    <span className="data-text appointment-doctor">{calendar.officialName}</span>
                </div>
                {appointment.atHome &&
                    <div className="data-row">
                        <i className="far fa-map"></i>
                        <span className="data-text appointment-address">{appointment.address}</span>
                    </div>
                }
                {appointment.notes &&
                    <div className="data-row">
                        <i className="fas fa-sticky-note"></i>
                        <span className="data-text appointment-note">{appointment.notes}</span>
                    </div>
                }
            </div>
            <hr/>
            <div className='pull-right flex'>
                <HelenaProButton label={I18n.get().t('cancel')} onClick={hidePopup}/>
                <HelenaProButton label={I18n.get().t('edit')} buttonType="primary" onClick={editAppointment}/>
            </div>
        </div>
    )
}
export default AppointmentPopup;