import camelize from "camelize";
import decamelizeObject from "shared/decamelize_object.js";

export default class PI {
  /**
   * This must be called in the main entry point of the application (e.g. in the /packs/ files)
   * railsRoutes is the routing file generated by Rails, an can be either AppRoutes or OracleRoutes
   */
  static initialize(railsRoutes) {
    return new Promise(resolve => {
      if (!this._instanceInitialized) {
        const camelizedProperties = camelize(railsRoutes);
        for (const prop in camelizedProperties) {
          PI[prop] = camelizedProperties[prop];
        }
        this._instanceInitialized = true;
      }
      resolve();
    });
  }

  static _validateOptions(options) {
    if (!options) {
      throw new Error("PI.get method doesn't have the correct param object");
    }
    if (!options.url) {
      throw new Error("PI.get method needs an url");
    }
  }

  //
  /**
   * this method is called when sending an object to the backend
   * it returns the object with decamelized properties
   * If there is no data object it returns an empty object
   */
  static _prepareSendObject = data => {
    if (data !== undefined) {
      try {
        return decamelizeObject(data);
      } catch (err) {
        throw new Error(err);
      }
    }
    return {};
  };

  // this is common for all request
  static _ajaxRequest(options, type) {
    PI._validateOptions(options);
    const {
      url,
      data,
      dataType,
      processData,
      contentType,
      preventResponseCamelize
    } = options;
    let ajaxDataType;
    if (dataType) {
      ajaxDataType = dataType;
    } else if (type === "GET") {
      // if we have a GET request and there's no dataType in options object, use by default "json" for dataType
      ajaxDataType = "json";
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: processData === false ? data : PI._prepareSendObject(data),
        type,
        dataType: ajaxDataType,
        processData: processData !== undefined ? processData : true,
        contentType: contentType !== undefined ? contentType : undefined,
        success: (data, status, xhr) => {
          resolve({
            data: preventResponseCamelize === true ? data : camelize(data),
            status,
            xhr
          });
        },
        error: (jqXHR, textStatus, errorThrown) => {
          reject({ jqXHR, textStatus, errorThrown });
        }
      });
    });
  }

  /** ALL THE METHODS BELOW ARE RETURNING A PROMISE */

  /**
   * GET method receives an options object with the properties defined below
   * url -> the endpoint to be called
   * data -> this is optional and can be an object which is sent to server
   * preventResponseCamelize -> if this is sent as TRUE the response received from server is not camelized
   * needed when receiving date keys, eg: 2018-10-01T00:00:00+02:00
   */
  static get(options) {
    return PI._ajaxRequest(options, "GET");
  }

  /**
   * POST method (and all of the above) receives an options object with the properties defined below
   * url -> the endpoint to be called
   * data -> this is optional and can be an object which is sent to server
   */
  static post(options) {
    return PI._ajaxRequest(options, "POST");
  }

  //PUT
  static put(options) {
    return PI._ajaxRequest(options, "PUT");
  }

  //PATCH
  static patch(options) {
    return PI._ajaxRequest(options, "PATCH");
  }

  //DELETE
  static delete(options) {
    return PI._ajaxRequest(options, "DELETE");
  }

  /**
   * This is a general request where we decide with the verb parameter what type of request it is
   * I created this while updating the app/javascript/admin/components/tasks/upsert_task_form.js file
   * and it's useful when we have just a different URL for a POST or UPDATE call
   */
  static customRequest(options, verb) {
    return PI._ajaxRequest(options, verb);
  }
}
