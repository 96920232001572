import "shared/initializers/polyfills.js";
import "intersection-observer";
import "patient/initializers/context_patient.js";
import "shared/initializers/matomo.js";
import "patient/initializers/mailcheck_domains.js";
import "admin/vendors/selectize.js";
import MiniSuggester from "patient/components/calendars/show/mini_suggester.js";
import MapPlaceholder from "patient/components/calendars/show/map_placeholder.js";
import CalendarSelectionProvider from "shared/components/suggesters/calendar_selection_provider.js";
import SuggestionStateProvider from "shared/components/suggesters/suggestion_state_provider.js";
import SuggestionProvider from "shared/components/suggesters/suggestion_provider.js";
import PatientCenterSuggester from "shared/components/suggesters/patient_center_suggester.js";
import AvailabilitiesIndex from "patient/components/availabilities/index.js";
import Calendar from "shared/models/calendar.js";
import Center from "shared/models/center.js";
import ReactTurbolinks from "patient/react_turbolinks.js";
import NewAppointment from "patient/html_enhancers/new_appointment.js";
import ShowAppointment from "patient/html_enhancers/show_appointment.js";
import CancelAppointment from "patient/html_enhancers/cancel_appointment.js";
import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import PI from "services/progenda_interface.js";

Rails.start();
Turbolinks.start();

window.I18n = I18n;

// For not-require jQuery plugins
window.$ = jQuery;
window.jQuery = jQuery;

// initialize the progenda interface
PI.initialize(AppRoutes);

var mountComponents = function() {
  // matomo tracking
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  I18n.setScope("patient");

  if ($("#preloaded-center").length > 0) {
    var center = Center.parseCenterHash(
        JSON.parse($("#preloaded-center").text())
    );
  }
  /* For one calendar */
  if ($("#preloaded-calendar").length > 0) {
    var calendar = Calendar.parseCalendarHash(
      JSON.parse($("#preloaded-calendar").text())
    );
    center.setCalendars([calendar]);
    if ($(".calendar-full-availabilities").length > 0) {
      calendar.loadServices().then(function(services) {
        ReactTurbolinks.mountComponent(
          () => (
            <SuggestionStateProvider
              selectedCalendars={[calendar]}
              forceServiceSelectIfOnlyService={true}
            >
              <SuggestionProvider splitType="FULL">
                <AvailabilitiesIndex />
              </SuggestionProvider>
            </SuggestionStateProvider>
          ),
          {},
          $(".calendar-full-availabilities").get(0)
        );
      });
    }
    $(".mini-suggester").each(function() {
      var calendar = center.getCalendarById($(this).data("calendarId"));
      ReactTurbolinks.mountComponent(
        () => (
          <SuggestionStateProvider selectedCalendars={[calendar]}>
            <SuggestionProvider splitType="PREVIEW_FULL">
              <MiniSuggester />
            </SuggestionProvider>
          </SuggestionStateProvider>
        ),
        {},
        $(this).get(0)
      );
    });
  }
  /* For multiple calendar */
  if ($("#preloaded-calendars").length > 0) {
    var calendars = Calendar.parseCalendarsHash(
      JSON.parse($("#preloaded-calendars").text())
    );
    center.setCalendars(calendars);
    if ($(".center-full-availabilities").length > 0) {
      ReactTurbolinks.mountComponent(
        () => (
          <CalendarSelectionProvider calendars={calendars}>
            <SuggestionStateProvider forceServiceSelectIfOnlyService={true}>
              <SuggestionProvider splitType="KEEP_CALENDAR_DURATION">
                <PatientCenterSuggester center={center} />
              </SuggestionProvider>
            </SuggestionStateProvider>
          </CalendarSelectionProvider>
        ),
        {},
        $(".center-full-availabilities").get(0)
      );
    }
    $(".mini-suggester").each(function() {
      var calendar = center.getCalendarById($(this).data("calendarId"));
      ReactTurbolinks.mountComponent(
        () => (
          <SuggestionStateProvider selectedCalendars={[calendar]}>
            <SuggestionProvider splitType="PREVIEW_FULL">
              <MiniSuggester />
            </SuggestionProvider>
          </SuggestionStateProvider>
        ),
        {},
        $(this).get(0)
      );
    });
  }

  if ($("#map-placeholder").length > 0) {
    ReactTurbolinks.mountComponent(
        () => (
            <MapPlaceholder calendars={center.calendars} />
        ),
        {},
        $("#map-placeholder").get(0)
    );
  }
  NewAppointment();
  ShowAppointment();
  CancelAppointment();
};

$(function() {
  mountComponents();
});

document.addEventListener("turbolinks:render", function() {
  mountComponents();
});
