import I18n from "i18n-js";
import fr from "./translations/fr.js.erb";
import en from "./translations/en.js.erb";
import nl from "./translations/nl.js.erb";
import es from "./translations/es.js.erb";
import el from "./translations/el.js.erb";
import tr from "./translations/tr.js.erb";
import de from "./translations/de.js.erb";


const I18nAndLocale = (function(){
    function loadLocale() {
        if ($("#preload-i18n").get(0)) {
            var defaultValues = JSON.parse($("#preload-i18n").text());
            I18n.defaultLocale = defaultValues.defaultLocale;
            I18n.locale = defaultValues.locale;
            I18n.fallbacks = true;
            moment.locale(defaultValues.locale);
            if (moment.tz !== undefined) {
                moment.tz.setDefault(defaultValues.timezone);
            }
        }
        interpolate(I18n);
        return I18n;
    }
    let i18nAndLocale = "";
    let self = {};
    let ready = new Promise(resolve => {
        self.resolve = resolve;
    });

    return {
        get: function(scope){
            if (!i18nAndLocale) {
                i18nAndLocale = new loadLocale(scope);
            }
            return i18nAndLocale;
        },

        setScope: function(scope) {
            if (!i18nAndLocale) {
                i18nAndLocale = new loadLocale(scope);
            }
            if (scope === "patient") {
                i18nAndLocale.translations = { fr: fr, en: en, nl: nl, es: es, el: el, tr: tr, de: de };
            } else {
                i18nAndLocale.translations = { fr: fr, en: en, nl: nl };
            }
            self.resolve()
        },

        ready: function() {
            return ready;
        }
    };

})();

let interpolate = function(i18n) {
    i18n.interpolate = function(message, options) { //Todo replace var
        options = this.prepareOptions(options);
        var matches = message.match(this.placeholder),
            placeholder,
            value,
            name,
            regex;

        if (!matches) {
            return message;
        }

        var value;

        while (matches.length) {
            placeholder = matches.shift();
            name = placeholder.replace(this.placeholder, "$1");

            var downcase = false,
                upcase = false;
            if (name.slice(-9) === ".downcase") {
                name = name.substr(0, name.length - 9);
                downcase = true;
            } else if (name.slice(-7) === ".upcase") {
                name = name.substr(0, name.length - 7);
                upcase = true;
            }

            if (this.isSet(options[name])) {
                value = options[name].toString().replace(/\$/gm, "_#$#_");
                if (downcase) {
                    value = value.charAt(0).toLowerCase() + value.slice(1);
                } else if (upcase) {
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                }
            } else if (name in options) {
                value = this.nullPlaceholder(placeholder, message, options);
            } else {
                value = this.missingPlaceholder(placeholder, message, options);
            }

            regex = new RegExp(
                placeholder.replace(/\{/gm, "\\{").replace(/\}/gm, "\\}")
            );
            message = message.replace(regex, value);
        }

        return message.replace(/_#\$#_/g, "$");
    };

}

export default I18nAndLocale;
