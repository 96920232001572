import ReactDOM from "react-dom";

var mountedComponentNodes = [];

$(document).on("turbolinks:click", function(event) {
  if ($(event.target).hasClass("anchor")) {
    return event.preventDefault();
  }
});

document.addEventListener("turbolinks:before-render", function() {
  var mountedComponentNodesClone = mountedComponentNodes;
  mountedComponentNodes = [];
  mountedComponentNodesClone.forEach(function(componentNode) {
    ReactDOM.unmountComponentAtNode(componentNode);
  });
});

export default {
  mountComponent: function(className, props, node) {
    var element = React.createElement(className, props);
    var component = ReactDOM.render(element, node);
    mountedComponentNodes.push(node);
    return component;
  }
};
