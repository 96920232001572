import ProgendaUtils from "./progenda_utils.js";
import { min, max, pluck } from "underscore";
import PI from "services/progenda_interface.js";
import CalendarUtilsReact from "shared/calendar_utils_react.js";
import AppointmentStatusLogo from "./components/admin/appointment_status_logo";

const allowedViewsHash = {
  "center": ["agendaDay", "agendaTwoDay", "agendaThreeDay", "agendaWeek", "withoutWeekends", "dayGridMonth"],
  "calendar": ["timeGridDay", "timeGridWeek", "withoutWeekends", "dayGridMonth"],
  "resource": ["timeGridDay", "timeGridWeek", "withoutWeekends"]
}

const CalendarUtils = {
  defaultView: function(FCComponent) {
    let allowedViews = allowedViewsHash[FCComponent];
    if (FCComponent === "center") {
      let view = ProgendaUtils.getStorageItem("centerView");
      return allowedViews.includes(view) ? view : "agendaTwoDay";
    }
    else if (FCComponent === "calendar") {
      let view = ProgendaUtils.getStorageItem("view");
      if (allowedViews.includes(view)) {
        return view;
      } else {
        return $(window).width() > 700 ? "timeGridWeek" : "withoutWeekends"
      }
    }
    else if (FCComponent === "resource") {
      let view = ProgendaUtils.getStorageItem("resourceView");
      return allowedViews.includes(view) ? view : "timeGridWeek";
    }
    else {
      return null;
    }
  },
  getSelectedCalendars: function(calendars) {
    var selectedCalendarsIds = ProgendaUtils.getStorageItem("defaultSelectedCalendars");
    if (selectedCalendarsIds !== null) {
      return calendars.filter(calendar => JSON.parse(selectedCalendarsIds).includes(calendar.id));
    } else {
      return [];
    }
  },
  setDefaultSelectedCalendars: function(selectedCalendars) {
    ProgendaUtils.setStorageItem("defaultSelectedCalendars", JSON.stringify(selectedCalendars));
  },

  getAvailablesFieldsPDF: function() {
    return {
      patientFields: [
        "birthdate",
        "phone_number",
        "email",
        "blacklist",
        "new_patient",
        "address",
        "title",
        "appointment_notes"
      ],
      appointmentFields: [
        "title",
        "service_name",
        "notes",
        "patient_notes",
        "notes_about_patient",
        "appointment_address",
        "at_home",
        "no_show"
      ]
    };
  },

  getSelectedFieldsPDF: function() {
    var fields = ProgendaUtils.getStorageItem("fieldsPDF");
    if (fields !== null) {
      return JSON.parse(fields);
    } else {
      return {
        patientFields: ["birthdate", "phone_number"],
        appointmentFields: [
          "title",
          "service_name",
          "notes",
          "patient_notes",
          "notes_about_patient"
        ],
        hideLayout: false
      };
    }
  },
  setSelectedFieldsPDF: function(fields) {
    ProgendaUtils.setStorageItem("fieldsPDF", JSON.stringify(fields));
  },

  getDisplayCanceledAppointments: function() {
    var display =
      ProgendaUtils.getStorageItem("displayCanceledAppointments") === "true";
    return display;
  },
  setDisplayCanceledAppointments: function(mode) {
    ProgendaUtils.setStorageItem("displayCanceledAppointments", mode);
  },
  getSelectedCalendarsFromURL: function(calendars) {
    let postcode = ProgendaUtils.getFromURL("postcode");
    let calendarIds = ProgendaUtils.getFromURL("calendar_ids[]");
    if (postcode !== null) {
      return calendars.filter(calendar =>
        calendar.postcodes.join(" ").includes(postcode)
      );
    }
    if(calendarIds !== null && calendarIds.length > 0){
      return calendars.filter(calendar => calendarIds.includes(calendar.id.toString()));
    }
  },
  makeSwipable: function(fullCalendarComponent) {
    if (ProgendaUtils.isMobile()) {
      var datePickerWithInput = fullCalendarComponent.props.getDatePickerWithInput();
      fullCalendarComponent.calendarJqueryElement.swipe({
        allowPageScroll: "vertical",
        preventDefaultEvents: false,
        excludedElements: [],
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
          if (event.type == "touchend" && $(".fc-helper").length === 0) {
            // do not swipe if selecting event
            fullCalendarComponent.isSwiping = true;
            datePickerWithInput.next();
            fullCalendarComponent.isSwiping = false;
          }
        },
        swipeRight: function(
          event,
          direction,
          distance,
          duration,
          fingerCount
        ) {
          if (event.type == "touchend" && $(".fc-helper").length === 0) {
            // do not swipe if selecting event
            fullCalendarComponent.isSwiping = true;
            datePickerWithInput.prev();
            fullCalendarComponent.isSwiping = false;
          }
        }
      });
    }
  },
  tooltipOnDate: function(
    element,
    event,
    alreadyFormatted = false,
    elementIsDragged = false
  ) {
    if (event.start === null) {return}
    var startFormated = ProgendaUtils.dateFormat(
      moment.tz(
        alreadyFormatted ? event.start : event.start.format(),
        event.tz
      ),
      "simple_time_format"
    );
    var stopFormated = ProgendaUtils.dateFormat(
      moment.tz(alreadyFormatted ? event.end : event.end.format(), event.tz),
      "simple_time_format"
    );
    var content = startFormated + " - " + stopFormated + "<br />" + event.title;
    var dateContainer = $(
      '<span class="icon-container custom-tooltip" data-toogle="tooltip" data-placement="bottom" data-trigger="hover" title="' +
        ProgendaUtils.filterXSS(content.replace(/"/g, "&#34;")) +
        '"></span>'
    );
    var start = "<span class='start-date'>" + startFormated + "</span>";
    var stop = "<span class='stop-date'> - " + stopFormated + "</span>";
    dateContainer.prepend(stop);
    dateContainer.prepend(start);
    element.find("div.fc-content .fc-time").prepend(dateContainer);
    if (!elementIsDragged) {
      // add the tooltip only if the element isn't dragged
      dateContainer.tooltip({ html: true, container: $(".fc-body") });
    }
  },
  tooltipOnHover: function(element, logo, text, elementIsDragged = false) {
    if (text !== undefined && text !== null && text !== "") {
      var elem = $(
        '<span class="icon-container custom-tooltip" data-toogle="tooltip" data-placement="bottom" data-trigger="hover" title="' +
          ProgendaUtils.filterXSS(text.replace(/"/g, "&#34;")) +
          '">' +
          '<i class="' +
          logo +
          '"></i>&nbsp;' +
          "</span>"
      );
      element.find("div.fc-content .fc-time").prepend(elem);
      if (!elementIsDragged) {
        elem.tooltip({ html: true, container: $(".fc-body") });
      }
    } else {
      element
        .find("div.fc-content .fc-time")
        .prepend('<i class="' + logo + '"></i>&nbsp;');
    }
  },
  patientArrivedAtListener: function(element, event) {
    const { extendedProps } = event;
    const patientNotArrived = extendedProps.patientArrivedAt === null;

    var elem = $(
      '<span class="icon-container"><i class="' +
        (patientNotArrived ? "clickable-fa " : "clicked-fa") +
        ' fa fa-thumbs-up"></i>&nbsp;</span>'
    );
    elem.on("click", e => {
      if (!extendedProps.patientArrivedAt) {
        element.find(".fa-thumbs-up").removeClass("clickable-fa");
        element.find(".fa-thumbs-up").addClass("clicked-fa");
      } else {
        element.find(".fa-thumbs-up").addClass("clickable-fa");
        element.find(".fa-thumbs-up").removeClass("clicked-fa");
      }

      let patientArrivedAt = null;
      patientArrivedAt = extendedProps.patientArrivedAt
        ? null
        : moment().unix();

      PI.patch({
        url: PI.adminCalendarAppointmentPath(extendedProps.calendarSlug, {
          id: event.id
        }),
        data: {
          appointment: {
            patientArrivedAt
          }
        }
      });
      e.preventDefault();
      e.stopPropagation();
    });
    if(!(extendedProps.helenaProUser && extendedProps.user && extendedProps.user.featureFlagPatientJourney)) {
      element.find("div.fc-content .fc-time").prepend(elem);
    }
  },
  patientCallListener: function(element, event) {
    var elem = $(
      '<span class="icon-container"><i class="fa fa-bell"></i>&nbsp;</span>'
    );
    elem.on("click", e => {
      PI.post({
        url: PI.adminCalendarAppointmentPatientCallsPath(
          event.extendedProps.calendarSlug,
          event.id
        )
      });
      e.preventDefault();
      e.stopPropagation();
    });
    element.find("div.fc-content .fc-time").prepend(elem);
  },
  copyAppointment: function(element, event) {
    const { extendedProps } = event;
    const isCopied = extendedProps.isCopied ;
    var elem = $(
        '<span class="icon-container"><i class="' + (isCopied ? "clicked-fa" : "clickable-fa")+ ' fa fa-copy"></i>&nbsp;</span>'
    );
    element.find("div.fc-content .fc-time").prepend(elem);
  },
  computeSlot: function(calendars) {
    if (Object.keys(calendars).length === 0) {
      return "00:60:00";
    }
    var start = this.getDisplayStart(calendars).get("minute");
    var averageTime = this.getAverageTime(calendars);
    if ((60 - start) % averageTime === 0 && 60 % averageTime === 0) {
      return "00:60:00";
    } else if (60 % start === 0 && 60 % averageTime === 0) {
      let startString = start.toString();
      if (startString.length === 1) {
        startString = `0${startString}`;
      }
      return "00:" + startString + ":00";
    } else {
      return "00:01:00";
    }
  },
  computeSlotDuration: function(averageTime) {
    let parsedAverageTime = parseInt(averageTime);
    if (parsedAverageTime === 0)
      return `00:60:00`
    else {
      let hours = parseInt(parsedAverageTime / 60);
      let minutes = parseInt(averageTime) % 60 ;
      return `${hours.toString().pad10()}:${minutes.toString().pad10()}:00`;
    }
  },
  getDisplayStart: function(calendars, returnFormattedTime = false) {
    let startValue;
    if (calendars.length === 0) {
      startValue = moment()
        .startOf("day")
        .hours(8);
    } else {
        startValue = min(calendars.map(function(calendar) {
              return moment(calendar.displayStart).utc();
        }));
    }
    if (returnFormattedTime) {
      return startValue.format(CalendarUtilsReact.timeDateFormats.time);
    }
    return startValue;
  },
  getDisplayEnd: function(calendars, returnFormattedTime = false) {
    let endValue;
    if (calendars.length === 0) {
      endValue = moment()
        .startOf("day")
        .hours(19)
    } else {
        endValue = max(calendars.map(function(calendar) {
          let start = moment.utc(calendar.displayStart, "YYYY-MM-DDTHH:mm:ssZ");
          let end = moment.utc(calendar.displayEnd, "YYYY-MM-DDTHH:mm:ssZ");
          let average = calendar.averageTime;
          let diffInHours = moment.duration(end.diff(start)).asHours();
          let diffInAverageTime = diffInHours / (average / 60);
          let roundedDiff = Math.ceil(diffInAverageTime); // go to the closest integer if it is not one
          let dayLength = roundedDiff * (average / 60);
          return moment(calendar.displayStart).utc().add(dayLength, 'hours');
        }));
    }
    if (returnFormattedTime) {
        return  endValue.date() > 1 ? '23:59:59' : endValue.format('HH:mm:ss')
    }
    return endValue;
  },
  getAverageTime: function(calendars, returnString = false) {
    if (Object.keys(calendars).length === 0) return 20;
    const averagesTime = pluck(calendars, "averageTime");
    const finalAverageTime = this.gcdArrays(averagesTime);
    if (
      finalAverageTime &&
      finalAverageTime.toString().length === 1 &&
      returnString
    ) {
      return `0${finalAverageTime}`;
    }
    return finalAverageTime;
  },
  gcdArrays: function(averagesTime) {
    if (averagesTime.length < 1) {
      return undefined;
    }
    while (averagesTime.length > 1) {
      const a_b = averagesTime.splice(0, 2); // get the two first elem
      const local_gcd = this.gcd(a_b[0], a_b[1]); // find gcd
      averagesTime.splice(0, 0, local_gcd); // add it on array
    }
    return averagesTime[0];
  },
  gcd: function(a, b) {
    if (a <= 0) return b;

    while (b > 0) {
      if (a > b) a = a - b;
      else b = b - a;
    }

    return a;
  },
  sortCalendars: function(calendars) {
    return calendars.sort(function(cal1, cal2) {
      var priority1 = cal1.priority;
      var priority2 = cal2.priority;
      var lastName1 = cal1.lastName.toLowerCase();
      var lastName2 = cal2.lastName.toLowerCase();
      var firstName1 = cal1.firstName.toLowerCase();
      var firstName2 = cal2.firstName.toLowerCase();
      var id1 = cal1.id;
      var id2 = cal2.id;

      if (priority1 > priority2) return 1;
      else if (priority1 < priority2) return -1;
      else if (lastName1 > lastName2) return 1;
      else if (lastName1 < lastName2) return -1;
      else if (firstName1 > firstName2) return 1;
      else if (firstName1 < firstName2) return -1;
      else if (id1 < id2) return -1;
      else if (id1 > id2) return 1;
      else return -1;
    });
  },

  sortfilters: function(filters) {
    return filters.sort(function(filter1, filter2) {
      var priority1 = filter1.priority;
      var priority2 = filter2.priority;
      var id1 = filter1.id;
      var id2 = filter2.id;

      if (priority1 > priority2) return 1;
      else if (priority1 < priority2) return -1;
      else if (id1 < id2) return -1;
      else if (id1 > id2) return 1;
      else return -1;
    });
  },

  getCurrentStatus: function(appointment, center){
      let currentTime = new Date().getTime();
      let status;
      let patientArrivedAt = appointment.patientArrivedAt ? moment(appointment.patientArrivedAt * 1000) : null;
      let isDone = appointment.isDone ? moment(appointment.isDone * 1000) : null;
      let inProgress = appointment.inProgress ? moment(appointment.inProgress * 1000) : null;
      let nbMinutesIsLate = parseInt(center.statusNbMinutesIsLate) * 60 * 1000;

      if(appointment.noShow){
        status = 'no_show';
      }
      else if (center.showStatus) {
        if (appointment.isDone && currentTime >= isDone) {
          status = center.showStatusDone ? 'is_done' : 'neutral';
        } else if (inProgress) {
          status = center.showStatusInProgress ? 'in_progress' : 'neutral';
        } else if (patientArrivedAt) {
          if (center.showStatusArrivedLate && appointment.arrivedLate) {
            status = 'arrived_late';
          } else {
            status = 'arrived';
          }
        } else if (center.showStatusLate &&
            !patientArrivedAt &&
            currentTime > (appointment.initialStart || appointment.start) * 1000 + nbMinutesIsLate &&
            currentTime < (appointment.initialStop || appointment.stop) * 1000) {
          status = 'late';
        } else {
          status = 'booked'
        }
      }
      return status;
  },
  computePopupEvent: function(jsEvent, $popupEvent)  {
    //compute position of the event
    let $fcBodyOffset = $('.fc-body')[0].getBoundingClientRect();
    let $event = $(jsEvent.target).closest('.fc-event');
    let pageOffset = $('.container')[0].getBoundingClientRect();
    let appointmentRect = $event[0].getBoundingClientRect();
    let $popupEventAppointment =  $popupEvent.find(`.appointment-popup`);
    let $fcHeadwrapperStickyOffset = $('.fc-head-wrapper-sticky')[0].getBoundingClientRect();

    //need to display it and set it as hidden to have an height and width. otherwhise it will be 0
    $popupEvent[0].style.visibility = 'hidden'
    $popupEvent[0].style.display = 'block'

    let popupEventWidth = $popupEventAppointment[0].offsetWidth;
    let popupEventHeight = $popupEventAppointment[0].offsetHeight;
    let computedTop = (Math.max(appointmentRect.top, pageOffset.top) + Math.min(appointmentRect.bottom, pageOffset.bottom ))/2 - ($fcBodyOffset.top + popupEventHeight / 2 ) ;
    let computedLeft = appointmentRect.left + appointmentRect.width + 10 - $fcBodyOffset.left;

    //define limit of the frame and default position of the popup
    let limitRight= $fcHeadwrapperStickyOffset.width;
    let limitTop = -$fcBodyOffset.top + $fcHeadwrapperStickyOffset.bottom;
    let limitBottom =  limitTop + window.innerHeight - $fcHeadwrapperStickyOffset.bottom;
    let limitLeft = 0;
    let arrowMidHeight = 5;
    let posTopArrow = popupEventHeight/2 - arrowMidHeight;
    let posLeftArrow = -4;
    let posRightArrow = 3;
    let arrowPositionClass= "";
    let popupEventPadding = 20;

    //check if the popup isn't lower than the bottom side of the frame
    if(computedTop + popupEventHeight > limitBottom){
      posTopArrow += computedTop + popupEventHeight - limitBottom + arrowMidHeight
      computedTop = limitBottom - popupEventHeight;
    }
    //check if the popup isn't higher than the top side of the frame
    if(computedTop < limitTop){
      computedTop = limitTop;
      posTopArrow = (Math.min(appointmentRect.bottom, pageOffset.bottom ) - Math.max(appointmentRect.top, pageOffset.top))/2 - arrowMidHeight + Math.max(appointmentRect.top, pageOffset.top) - computedTop - $fcBodyOffset.top;
    }

    //check if the popup isn't righter than the right side of the frame
    if(computedLeft + popupEventWidth > limitRight){
      arrowPositionClass = 'arrow-right';
      posLeftArrow = popupEventWidth + posRightArrow;
      if(computedLeft - popupEventWidth - appointmentRect.width - popupEventPadding < 0){
        computedLeft = limitRight - popupEventWidth
      }else {
        computedLeft = computedLeft - popupEventWidth - appointmentRect.width - popupEventPadding - arrowMidHeight;
      }
    }

    $popupEvent.find('.arrow').css({
      top: posTopArrow+ 'px',
      left: posLeftArrow+ 'px'
    }).addClass(arrowPositionClass);

    $popupEvent.css({
      display: 'block',
      position: "absolute",
      top: computedTop + "px",
      left: computedLeft + "px",
      visibility: 'visible'
    });
  }
};

export default CalendarUtils;

