import Secrets from "shared/secrets.js";

var googleMapsLoaded = false;
var listenersPlaced = false;
var callback = null;

var GoogleMapsLoader = {
  loadMap: function() {
    callback(function(mapSelector, calendars) {
      var first = calendars[0];
      var calendarLatLng = new google.maps.LatLng(first.lat, first.lon);
      var mapOptions = {
        center: calendarLatLng,
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false
      };
      var gMap = new google.maps.Map(mapSelector, mapOptions);
      calendars.forEach(function(calendar) {
        new google.maps.Marker({
          position: new google.maps.LatLng(calendar.lat, calendar.lon),
          map: gMap,
          title: calendar.fullName
        });
      });
    });
  },
  loadGoogle: function() {
    if (!googleMapsLoaded) {
      googleMapsLoaded = true;
      window.mapsCallback = function() {
        GoogleMapsLoader.loadMap();
      };
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        Secrets.get().googleMapsClientKey +
        "&callback=mapsCallback";
      document.body.appendChild(script);
    } else {
      GoogleMapsLoader.loadMap();
    }
  },
  load: function(initializeMap) {
    callback = initializeMap;
    GoogleMapsLoader.loadGoogle();
  }
};

export default GoogleMapsLoader;
