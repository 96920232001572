import unFormatSsin from '../formatting/unFormat';
import parseSsin from '../parsing/parse';
import isBisNumber from './isBisNumber';

// When bis number
// - Gender is know: Month is increased with 40
// - Gender is unknown: Month is increased with 20
const getBisNumberGender = (bis) => {
  const value = unFormatSsin(bis);
  if (!value || !isBisNumber(value)) {
    return undefined;
  }

  const { month, sequence } = parseSsin(value);
  if (month >= 40) {
    return sequence % 2 === 0 ? 'FEMALE' : 'MALE';
  }
  return undefined;
};

export default getBisNumberGender;
