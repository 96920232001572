import { string } from 'yup';

import unFormatSsin from '../formatting/unFormat';
import parseSsin from '../parsing/parse';

// when birthdate is missing, the standard '00' day must be used to calculate the module

const ssinCheckModulo97Before2000 = (ssin) => {
  const { year, month, day, sequence, control, hasMissingBirthDate } = parseSsin(ssin);
  const ssinNumber = `${year}${month}${hasMissingBirthDate ? '00' : day}${sequence}`;
  return 97 - (parseInt(ssinNumber, 10) % 97) === parseInt(control, 10);
};

const ssinCheckModulo97After2000 = (ssin) => {
  const { year, month, day, sequence, control, hasMissingBirthDate } = parseSsin(ssin);
  const ssinNumber = `${year}${month}${hasMissingBirthDate ? '00' : day}${sequence}`;
  return 97 - (parseInt(`2${ssinNumber}`, 10) % 97) === parseInt(control, 10);
};

const ssinCheckModulo97 = (ssin) => {
  const value = unFormatSsin(ssin);
  if (!value) {
    return false;
  }

  try {
    return ssinCheckModulo97Before2000(value) || ssinCheckModulo97After2000(value);
  } catch (err) {
    return false;
  }
};

export { ssinCheckModulo97, ssinCheckModulo97After2000, ssinCheckModulo97Before2000 };

export default string()
  .required('ssin.invalid.required')
  .test('invalidssin', 'ssin.invalid.structure', (ssin = '') => {
    // Length checks
    if (ssin === '') {
      return true;
    }

    // Check modulo 97
    return ssinCheckModulo97(ssin);
  });
