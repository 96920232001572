import parseSsin from '../parsing/parse';

const transformUnFormatted = (ssin) => {
  if (!ssin || ssin === '') {
    return undefined;
  }

  const { year, month, day, sequence, control } = parseSsin(ssin);

  return `${year}${month}${day}${sequence}${control}`;
};

export default transformUnFormatted;
