import unFormatSsin from '../formatting/unFormat';
import { ssinCheckModulo97 } from '../validation/validation';

const isBisNumber = (ssin) => {
  const value = unFormatSsin(ssin);
  if (!value) {
    return false;
  }

  const month = value.substring(2, 4);
  return !!((month > 12 || month === '00') && ssinCheckModulo97(value));
};

export default isBisNumber;
