/**
 * This is a method which does a deep decamelize for object keys.
 * It was needed and first use on app/javascript/services/progenda_interface.js when sending
 * data to server
 */

import decamelize from "decamelize";
import forOwn from "lodash/forOwn";
import isArray from "lodash/isArray";

const decamelizeObject = data => {
  if (data === null || data === undefined) {
    return data;
  }

  if (typeof data !== "object" && !isArray(data)) {
    return data;
  }

  let decamelizedObject = {};

  if (isArray(data)) {
    decamelizedObject = [];
    data.forEach(dataItem => {
      decamelizedObject.push(decamelizeObject(dataItem));
    });
  } else {
    forOwn(data, (value, key) => {
      const decamelizedKey = decamelize(key);
      decamelizedObject[decamelizedKey] = decamelizeObject(value);
    });
  }
  return decamelizedObject;
};

export default decamelizeObject;
