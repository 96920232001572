export default class SuggestionGroupProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { groupedSuggestions: [] };
  }
  groupByDay(suggestions) {
    const map = new Map();
    suggestions.forEach(suggestion => {
      const day = suggestion.start
        .clone()
        .startOf("day")
        .format();
      const collection = map.get(day);
      if (!collection) {
        map.set(day, [suggestion]);
      } else {
        collection.push(suggestion);
      }
    });
    return map;
  }
  groupByTime(suggestions) {
    const map = new Map();
    suggestions.forEach(suggestion => {
      const time = suggestion.start.format();
      const collection = map.get(time);
      if (!collection) {
        map.set(time, [suggestion]);
      } else {
        collection.push(suggestion);
      }
    });
    return map;
  }
  /* This can be removed when not supporting IE 11 anymore */
  arrayFromMap(map) {
    if (!Array.from) {
      const array = [];
      map.forEach((value, key) => array.push([key, value]));
      return array;
    } else {
      return Array.from(map);
    }
  }
  groupSuggestions() {
    const suggestions = this.props.suggestions;
    const sortedSuggestions = suggestions
      .slice()
      .sort(
        (suggestionA, suggestionB) => suggestionA.start - suggestionB.start
      );
    const suggestionsByDay = this.groupByDay(sortedSuggestions);
    this.setState({
      groupedSuggestions: this.arrayFromMap(suggestionsByDay).map(
        ([day, suggestions]) => [
          day,
          this.arrayFromMap(this.groupByTime(suggestions))
        ]
      )
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.suggestions &&
      this.props.suggestions !== prevProps.suggestions
    ) {
      this.groupSuggestions();
    }
  }
  render() {
    const childWithProp = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        ...this.props,
        groupedSuggestions: this.state.groupedSuggestions
      });
    });

    return childWithProp;
  }
}
