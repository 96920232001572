import React from "react";
import ProgendaUtils from "shared/progenda_utils.js";
var XSSFilter = function (_a) {
    var useSpan = _a.useSpan, content = _a.content, className = _a.className, allowLinks = _a.allowLinks;
    var XSSComponent = useSpan ? "span" : "div";
    return (React.createElement(XSSComponent, { className: className, dangerouslySetInnerHTML: {
            __html: ProgendaUtils.filterXSS(content, false, false, allowLinks)
        } }));
};
XSSFilter.defaultProps = {
    useSpan: false
};
export default XSSFilter;
try {
    // @ts-ignore
    xssfilter.displayName = "xssfilter";
    // @ts-ignore
    xssfilter.__docgenInfo = { "description": "", "displayName": "xssfilter", "props": { "content": { "defaultValue": null, "description": "The content which must be filtered", "name": "content", "required": true, "type": { "name": "string" } }, "useSpan": { "defaultValue": { value: false }, "description": "If the element will be a span or a div", "name": "useSpan", "required": false, "type": { "name": "boolean" } }, "className": { "defaultValue": null, "description": "A custom css class name", "name": "className", "required": false, "type": { "name": "string" } }, "allowLinks": { "defaultValue": null, "description": "If a with tag href is in whiteList", "name": "allowLinks", "required": false, "type": { "name": "boolean" } } } };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["app/javascript/shared/components/xss_filter.tsx#xssfilter"] = { docgenInfo: xssfilter.__docgenInfo, name: "xssfilter", path: "app/javascript/shared/components/xss_filter.tsx#xssfilter" };
}
catch (__react_docgen_typescript_loader_error) { }
