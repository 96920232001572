import getBisNumberMonth from '../bisNumber/getBisNumberMonth';
import isBisNumber from '../bisNumber/isBisNumber';
import unFormatSsin from '../formatting/unFormat';
import { ssinCheckModulo97After2000, ssinCheckModulo97Before2000 } from '../validation/validation';
import parseSsin from './parse';

const getYearPrefix = (ssin) => {
  let yearPrefix = '';
  if (ssinCheckModulo97Before2000(ssin)) {
    yearPrefix = '19';
  } else if (ssinCheckModulo97After2000(ssin)) {
    yearPrefix = '20';
  }
  return yearPrefix;
};

const getMonth = (ssin, month) => {
  let modifiedMonth = month;
  if (isBisNumber(ssin)) {
    modifiedMonth = getBisNumberMonth(ssin);
  }
  return modifiedMonth;
};

const getSsinBirthDate = (ssinVal) => {
  const ssin = unFormatSsin(ssinVal);
  if (!ssin) {
    return undefined;
  }

  let birthDate;
  const { year, month, day } = parseSsin(ssin);
  try {
    const yearPrefix = getYearPrefix(ssin);
    const modifiedMonth = getMonth(ssin, month);

    if (modifiedMonth === '00' || day === '00') {
      birthDate = `${day}-${modifiedMonth}-${yearPrefix + year}`;
    } else {
      birthDate = new Date(
        parseInt(yearPrefix + year, 10),
        parseInt(modifiedMonth - 1, 10),
        parseInt(day, 10),
        0,
        0,
        0,
        0
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Unable to parse ssin: ', ssin);
  }
  return birthDate;
};

export default getSsinBirthDate;
