import ProgendaUtils from "shared/progenda_utils.js";
import InfiniteScroll from "react-infinite-scroller";
import ProgendaContext from "shared/progenda_context";
import { Link } from "react-router-dom";
import XSSFilter from "shared/components/xss_filter.tsx";
import HelenaProButton from "../../../admin/components/helena_pro/shared/HelenaProButton";

export default class CenterSuggester extends React.Component {
  constructor(props) {
    super(props);
    this.state = { maxResults: 10 };
  }

  setSelectedServiceCode = event => {
    var serviceCode = event.target.value;
    this.props.setSelectedServiceCodes([serviceCode]);
  };

  showMoreResults = () => {
    this.setState({ maxResults: this.state.maxResults + 10 });
  };
  render() {
    const {
      selectedServiceCodes,
      loadingSuggestions,
      suggestions,
      center,
      groupedSuggestions,
      goToSuggestion,
      useHelenaProDesign
    } = this.props;

    if (selectedServiceCodes.length === 0) {
      return (
        <div className="text-center">
          <br />
          <br />
          <div className="alert alert-warning">
            {I18n.get().t("no_results_search_explanation")}
          </div>
        </div>
      );
    }
    if (loadingSuggestions) {
      return <div className="progenda-spinner"></div>;
    }
    if (suggestions.length === 0) {
      return (
        <div className="text-center">
          <br />
          <br />
          <span className="alert alert-warning display-inline-block">
            {ProgendaContext.getContext() === "ADMIN"
              ? I18n.get().t("no_more_availabilities_on_admin_search")
              : (
                  <XSSFilter
                    useSpan={true}
                    content={center.noAvailabilitiesText}
                    allowLinks={true}
                  />
                ) || I18n.get().t("no_more_availabilities_on_patient_search")}
          </span>
        </div>
      );
    }

    return (
      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.showMoreResults}
          hasMore={this.state.maxResults <= groupedSuggestions.length}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <table className="center-search-table table table-resp table-striped">
            <tbody>
              {groupedSuggestions
                .slice(0, this.state.maxResults)
                .map(([day, suggestions]) => [
                  <tr
                    key={`center-search-header-first-line-${day}`}
                    className="center-search-header"
                  >
                    <td colSpan="3">
                      <h3>
                        {ProgendaUtils.dateFormat(
                          day,
                          "long_date_format"
                        ).capitalize()}
                      </h3>
                    </td>
                  </tr>,
                  <tr
                    key={`center-search-header-second-line-${day}`}
                    className="center-search-header"
                  >
                    <th>{I18n.get().t("start")}</th>
                    <th>{I18n.get().t(center.calendarDesignation)}</th>
                    <th></th>
                  </tr>,
                  ...suggestions.map(([time, suggestions]) =>
                    suggestions.map((suggestion, index) => (
                      <tr
                        key={`first-group-availability-${index.toString()}`}
                        className={"first-group-availability"}
                      >
                        <td>
                          {ProgendaUtils.dateFormat(
                            suggestion.start,
                            "simple_time_format"
                          )}
                        </td>
                        <td>
                            {useHelenaProDesign ?
                                <div className="avatar-initials">
                                    {suggestion.calendar.firstName.charAt(0)}{suggestion.calendar.lastName.charAt(0)}
                                </div>
                                :
                                <img
                                src={suggestion.calendar.smallPicture}
                                className="profile-thumb img-rounded"
                            />
                            }
                          &nbsp;&nbsp;
                          {suggestion.calendar.officialNameWithAdditional}
                        </td>
                          {useHelenaProDesign ?
                              <td className="center-search-buttons">
                                  {ProgendaContext.getContext() === "ADMIN" ? (
                                      <span>
                              <Link
                                  to={suggestion.url}
                                  className="btn btn-default"
                              >
                                  <span><span className="fas fa-plus"></span> &nbsp;</span>
                                {I18n.get().t("make_an_appointment")}
                              </Link>
                              &nbsp;&nbsp;
                                          <HelenaProButton label={I18n.get().t("display_on_calendar")} icon="fas fa-calendar" buttonType="default" onClick={() => goToSuggestion(suggestion)}/>
                            </span>
                                  ) : <HelenaProButton label={I18n.get().t("display_on_calendar")} icon="fas fa-calendar" buttonType="default" onClick={() => goToSuggestion(suggestion)}/>}
                              </td>
                              :
                              <td>
                              {ProgendaContext.getContext() === "ADMIN" ? (
                                  <span>
                              <Link
                                  to={suggestion.url}
                                  className="btn btn-xs btn-default"
                              >
                                {I18n.get().t("make_an_appointment")}
                              </Link>
                                      &nbsp;&nbsp;
                                      <a
                                          onClick={() => {
                                              goToSuggestion(suggestion);
                                          }}
                                          className="btn btn-xs btn-default"
                                      >
                                {I18n.get().t("display_on_calendar")}
                              </a>
                            </span>
                              ) : (
                                  <a
                                      href={suggestion.url}
                                      className="btn btn-xs btn-default"
                                  >
                                      {I18n.get().t("make_an_appointment")}
                                  </a>
                              )}
                          </td>}
                      </tr>
                    ))
                  )
                ])}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    );
  }
}
