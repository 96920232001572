import SelectFilter from "./select_filter.js";

export default class FilterCalendars extends React.Component {
  state = { calendars: [] };

  componentDidMount() {
    this.loadCalendars(this.props.selectedCalendars);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCalendars !== this.props.selectedCalendars) {
      this.loadCalendars(this.props.selectedCalendars);
    }
  }

  loadCalendars = calendars => {
    this.setState({
      calendars: calendars.map(calendar => ({
        id: calendar.id,
        name: calendar.officialNameWithAdditional
      }))
    });
  };

  toggleCalendar = calendar => {
    const { calendarFilter, setCalendarFilter } = this.props;
    if (calendarFilter.includes(calendar)) {
      setCalendarFilter(calendarFilter.filter(c => c !== calendar));
    } else {
      setCalendarFilter([...calendarFilter, calendar]);
    }
  };

  render() {
    const { center, calendarFilter } = this.props;
    return (
      <SelectFilter
        title={I18n.get().t(center.calendarDesignation + "_plural")}
        resources={this.state.calendars}
        selectedResourceIds={calendarFilter}
        toggleResource={this.toggleCalendar}
      />
    );
  }
}
