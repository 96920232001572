import getBisNumberGender from '../bisNumber/getBisNumberGender';
import isBisNumber from '../bisNumber/isBisNumber';
import unFormatSsin from '../formatting/unFormat';
import parseSsin from './parse';

const getSsinGender = (ssin) => {
  const value = unFormatSsin(ssin);
  if (!value) {
    return undefined;
  }

  const isBis = isBisNumber(value);
  let gender;

  const { sequence, month } = parseSsin(value);

  if (isBis && month !== '00') {
    gender = getBisNumberGender(ssin);
  } else {
    gender = sequence % 2 === 0 ? 'FEMALE' : 'MALE';
  }
  return gender;
};

export default getSsinGender;
