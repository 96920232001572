import Tooltip from "shared/components/tooltip.tsx";

export default class HField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    var subLabel = this.props.tooltipFor || this.props.for;
    var tooltipText =
      subLabel &&
      I18n.get().t(subLabel + "_tooltip", { defaultValue: "null" }) !== "null" &&
      I18n.get().t(subLabel + "_tooltip", {
        contact_designation: this.props.contactDesignation
      });
    var tooltip = (
      <Tooltip tag="span" title={tooltipText}>
        <i className="fa fa-info-circle"></i>&nbsp;
      </Tooltip>
    );

    switch (this.props.layout) {
      case "v": // Vertical
        return (
          <div className="form-group v-layout">
            {this.props.label && (
              <label
                className={
                  this.props.labelClass !== undefined
                    ? this.props.labelClass
                    : "control-label col-sm-12 no-padding-left-helena"
                }
                htmlFor={this.props.htmlFor}
              >
                {this.props.label}
                {this.props.extraDescriptionLine && (
                  <React.Fragment>
                    <br />
                    <span className="sub-label">
                      {I18n.get().t(this.props.extraDescriptionLine)}
                    </span>
                  </React.Fragment>
                )}
                <br />
                {tooltipText && (
                  <span className="sub-label">{tooltipText}</span>
                )}
              </label>
            )}
            <div className={this.props.fieldClass ? this.props.fieldClass : "col-sm-12 no-padding-left-helena"}>
              {this.props.children}
            </div>
          </div>
        );
      case "n": // None
        return <div className="form-group">{this.props.children}</div>;
      case "i": // Inline
        return this.props.children;
      default:
        // Horizontal
        return (
          <div className="form-group">
            <label
              className={
                this.props.labelClass !== undefined
                  ? this.props.labelClass
                  : "control-label col-sm-4"
              }
              htmlFor={this.props.htmlFor}
            >
              {tooltip}
              {this.props.label}
            </label>
            <div className={this.props.fieldClass ? this.props.fieldClass :  "col-sm-6"}>
              {this.props.children}
            </div>
          </div>
        );
    }
  }
}
