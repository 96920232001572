import Checkbox from "shared/components/checkbox.tsx";
import HInput from "../forms/h_input";

export default class SelectFilter extends React.Component {
  state = { search: "" };

  componentDidMount() {
    $(this._dropdown).on({
      click: event => {
        if ($(event.target).closest(".dropdown-toggle").length) {
          this.closable = true;
        } else {
          this.closable = false;
        }
      },
      "hide.bs.dropdown": e => {
        const hide = this.closable;
        this.closable = true;
        return hide;
      }
    });
  }

  toggleResource = resourceId => {
    this.closable = true;
    this.props.toggleResource(resourceId);
  };

  render() {
    if (this.props.resources.length < 2) {
      return <span />;
    }
    const resources = this.props.resources.filter(resource =>
      resource.name.toLowerCase().includes(this.state.search.toLowerCase())
    );
    return (
      <span className="btn-group" role="group" ref={c => (this._dropdown = c)}>
        <button
          type="button"
          className="btn btn-sm btn-default dropdown-toggle chevron-down"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {this.props.title}&nbsp;&nbsp;<span className={this.props.useHelenaProDesign ? "fas fa-chevron-down" : "caret"}></span>
        </button>
        <ul className="dropdown-menu dropdown-menu-condensed cursor-pointer">
          {this.props.enableSearch && (
            <li>
              <a>
                <HInput
                  layout="n"
                  value={this.state.search}
                  onChange={event =>
                    this.setState({ search: event.target.value })
                  }
                  params={{ placeholder: I18n.get().t("search_view") }}
                />
              </a>
            </li>
          )}
          {resources.map(resource => (
            <li key={`select_filter_${resource.id}`}>
              <a>
                <Checkbox
                  checked={this.props.selectedResourceIds.includes(resource.id)}
                  onChange={() => {
                    this.toggleResource(resource.id);
                  }}
                  label={resource.name}
                />
              </a>
            </li>
          ))}
        </ul>
      </span>
    );
  }
}
