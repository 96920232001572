import { string, number, shape } from "prop-types";
import LinkTo from "shared/components/link_to.js";
import ProgendaUtils from "shared/progenda_utils.js";
import PI from "services/progenda_interface.js";

const AppointmentCancellationPage = ({
  type,
  calendar: { title, fullName, phoneNumber, slug },
  appointment
}) => {
  return (
    <div className="well col-sm-offset-1 col-sm-10">
      <strong>{I18n.get().t("cancellation_title")}</strong>
      <br />
      <br />

      {type === "appointmentNotReachable" && (
        <React.Fragment>
          {I18n.get().t("appointment_not_reachable", {
            title: I18n.get().t(title),
            name: fullName
          })}
          <br />
          <br />
          <br />
          {I18n.get().t("please_contact_calendar_owner", {
            title: I18n.get().t(title),
            name: fullName
          })}
          {phoneNumber && phoneNumber}
          <br />
          <br />
          <LinkTo text={I18n.get().t("ok")} path={PI.calendarPath(slug)} />
        </React.Fragment>
      )}
      {type === "appointmentAlreadyCanceled" && (
        <React.Fragment>
          {I18n.get().t("already_canceled", { title: I18n.get().t(title), name: fullName })}
          <br />
          <br />
          <LinkTo text={I18n.get().t("ok")} path={PI.calendarPath(slug)} />
        </React.Fragment>
      )}
      {type === "appointmentCancellationNotAllowed" && (
        <React.Fragment>
          {I18n.get().t("cancellation_not_allowed", {
            title: I18n.get().t(title),
            name: fullName
          })}
          {phoneNumber && phoneNumber}
          <br />
          <br />
          <LinkTo text={I18n.get().t("ok")} path={PI.calendarPath(slug)} />
        </React.Fragment>
      )}
      {type === "appointmentCancellationConfirmation" && (
        <React.Fragment>
          {I18n.get().t("confirm_cancel_appointment", {
            title: I18n.get().t(title),
            name: fullName
          })}
          <br />
          <br />
          {appointment && appointment.id && (
            <LinkTo
              text={I18n.get().t("cancel_my_appointment")}
              path={PI.calendarAppointmentCancellationPath({
                cancel_token: ProgendaUtils.getFromURL("cancel_token"),
                calendar_id: slug,
                appointment_id: appointment.id
              })}
              disableLongPress={true}
              method="post"
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

AppointmentCancellationPage.propTypes = {
  type: string,
  calendar: shape({
    type: string,
    fullName: string,
    phoneNumber: string,
    slug: string
  }),
  appointment: shape({
    id: number
  })
};

export default AppointmentCancellationPage;
