export default class InputGroup extends React.Component {
  render() {
    if (!this.props.label) {
      return <input {...this.props} />;
    }
    return (
      <div className="input-group">
        <input {...this.props} />
        <span className="input-group-addon input-sm">{this.props.label}</span>
      </div>
    );
  }
}
