import StepModal from "shared/components/step_modal.js";

import CalendarFilterGroupModal from "./calendar_filter_group_modal.js";
import ServiceFilterGroupModal from "./service_filter_group_modal.js";
import ServiceSelectModal from "./service_select_modal.js";

export default class StepFilterGroupModal extends React.Component {
  constructor(props) {
    super(props);
    const showCalendarGroup = this.props.center.calendarFilters.length > 0;
    const showServiceGroup = this.props.center.serviceFilters.length > 0;

    var validServiceGroups = this.getValidServiceGroups(props);
    this.state = {
      showCalendarGroup: showCalendarGroup && this.props.displayCalendarGroups, // do not display if already defined in URL
      showServiceGroup: showServiceGroup && this.props.displayServiceGroups, // do not display if already defined in URL
      validServiceGroups: validServiceGroups
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.services.length !== nextProps.services.length) {
      this.setState({
        validServiceGroups: this.getValidServiceGroups(nextProps)
      });
    }
  }

  getValidServiceGroups(props) {
    var serviceLabels = props.services.map(s => s.label);
    var serviceFilters = JSON.parse(JSON.stringify(props.center.serviceFilters)) // Deep copy
      .filter(function(f) {
        f.serviceCodes = f.serviceCodes.filter(c => serviceLabels.includes(c));
        return f.serviceCodes.length > 0;
      });
    return serviceFilters;
  }

  show = () => {
    if (this._stepFilterModal) {
      this._stepFilterModal.show();
    }
  };

  hide = () => {
    if (this._stepFilterModal && this._stepFilterModal.visible()) {
      this._stepFilterModal.hide(() => {});
    }
  };

  reset = () => {
    this._stepFilterModal.reset();
    this.props.resetCalendarGroup();
    this.props.resetServiceGroup();
  };

  render() {
    return (
      <StepModal ref={c => (this._stepFilterModal = c)} large={true}>
        {this.state.showCalendarGroup && (
          <CalendarFilterGroupModal
            center={this.props.center}
            selectCalendarGroup={this.props.selectCalendarGroup}
          />
        )}
        {this.state.showServiceGroup &&
          this.state.validServiceGroups.length > 0 && (
            <ServiceFilterGroupModal
              selectServiceGroup={this.props.selectServiceGroup}
              resetCalendarGroup={this.props.resetCalendarGroup}
              showCalendarGroup={this.state.showCalendarGroup}
              center={this.props.center}
              serviceFilters={this.state.validServiceGroups}
              back={this.state.showCalendarGroup ? this.reset : undefined}
            />
          )}
        <ServiceSelectModal
          ref={c => (this._serviceModal = c)}
          center={this.props.center}
          services={this.props.services}
          serviceSelectText={this.props.serviceSelectText}
          selectedServiceCodes={this.props.selectedServiceCodes}
          setSelectedServiceCodes={this.props.setSelectedServiceCodes}
          back={
            this.state.showCalendarGroup || this.state.showServiceGroup
              ? this.reset
              : undefined
          }
        />
      </StepModal>
    );
  }
}
