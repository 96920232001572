import { useEffect, useRef } from "react";
var useDidMountEffect = function (func, deps) {
    /**
     * useRef is used to store mutable values, so we can keep track if the component did mount or not
     */
    var didMount = useRef(false);
    useEffect(function () {
        if (didMount.current) {
            func();
        }
        else {
            didMount.current = true;
        }
    }, deps);
};
export default useDidMountEffect;
